import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const getProjGQL = loader('./getProj.gql');

export const useProj = () => {
  const { data } = useQuery(getProjGQL, { fetchPolicy: 'cache-first' });
  const projects = data?.getProj ?? [];

  const isProjectEqualById = (id, ref) => {
    return projects.find((r) => r.id === id)?.ref === ref;
  };

  return {
    proj: projects,
    isProjectEqualById,
  };
};
