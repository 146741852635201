import { DocInfoBar, StickyBar } from 'components';

import React, { useCallback, useRef, useState } from 'react';
import { OptionsBar } from './optionsBar/optionsBar';
import { DataGrid } from 'devextreme-react';
import { Column, MasterDetail } from 'devextreme-react/data-grid';
import { exportExcel } from 'utils/export-excel';
import { useLocation } from 'react-router';

export const InsurancePriceList = () => {
  const [data, setData] = useState([]);
  const gridRef = useRef();
  const location = useLocation();

  const handleExport = useCallback(() => exportExcel('insPrice', gridRef.current.instance), []);

  return (
    <>
      <StickyBar>
        <DocInfoBar name='Ціни (автоцивілка)' />
        <OptionsBar onChange={setData} onExport={handleExport} />
      </StickyBar>
      <DataGrid
        width={'100%'}
        ref={gridRef}
        id={location.pathname}
        paging={{ enabled: false }}
        className='otk-datagrid-fullpage width-100'
        dataSource={data}
        columnResizingMode='nextColumn'
        highlightChanges
        allowColumnReordering
        allowColumnResizing
        showBorders
        remoteOperations
        columnAutoWidth
        columnChooser={{ enabled: false, mode: 'select', height: 350 }}
        onContentReady={(e) => {
          const columnChooserView = e.component.getView('columnChooserView');
          if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
          }
          columnChooserView._popupContainer.option('position', {
            of: e.element,
            my: 'right top',
            at: 'right top',
            offset: '0 50',
          });
        }}
      >
        <Column
          dataField={'vehicleType.title'}
          caption='Тип'
          dataType='string'
          alignment='left'
          allowHiding={false}
          sortOrder={'asc'}
          allowResizing={true}
          width={150}
        />
        <Column dataField={'name'} caption='СК' dataType='string' alignment='left' allowHiding={false} />
        <Column dataField='zPrice' caption='Ціна' dataType='string' alignment='right' allowHiding={false} />
        <MasterDetail enabled={true} component={DetailTemplateBySK} />
      </DataGrid>
    </>
  );
};

const DetailTemplateBySK = (props) => {
  const dataSource = props.data.data.tariffs;
  return (
    <React.Fragment>
      <DataGrid dataSource={dataSource} showBorders={true} columnAutoWidth={true}>
        <Column dataField='franchise' caption='Франшиза' />
        <Column dataField='price' caption='Ціна' />
      </DataGrid>
    </React.Fragment>
  );
};
