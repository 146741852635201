import { gql } from 'graphql.macro';
import { gqlClient } from 'gql-client';
import { DEFAULT_AVATAR_URL } from 'app-constants';
import * as Sentry from '@sentry/react';

const AUTH = gql`
  query getAuth($user: String, $pass: String) {
    auth(name: $user, pass: $pass)
  }
`;

const LOGOUT = gql`
  query logout {
    logout
  }
`;

const GETOPTIONS = gql`
  query getOptions($branch:String){ 
    getOptions (branch:$branch)
  }
`;

const LOADINIT = gql`
  query loadInit($branch:String){ 
    getOptions (branch:$branch)
    getLab (with_price:true)
    getProj { id ref name predefined_name }
  }
`;

const getAuth = async (user, pass) => gqlClient.query({
  query: AUTH,
  variables: { user, pass },
})
  .then((response) => {
    if (response.data.auth) response.data.auth.isOk = true;
    return response.data?.auth || { isOk: false };
  })
  .catch((e) => Sentry.captureException(e));

export const logout = async () => gqlClient.query({ query: LOGOUT })
  .then((response) => response.data.logout);

export const loadOptions = async (branch) => gqlClient.query({
  query: GETOPTIONS,
  variables: { branch },
})
  .then((response) => (response.data.getOptions || {}),
  )
  .catch((e) => console.error('getOptions failed', e));

export const getInit = async (user) => gqlClient.query({
  query: LOADINIT,
  variables: { branch: user.branch },
})
  .then((response) => ({
    options: response.data.getOptions,
    lab: response.data.getLab[0],
    proj: response.data.getProj,
  }))
  .catch((e) => console.error('getInit failed', e));

export const signIn = async (email, password) => getAuth(email, password)
  .then((result) => ({
    isOk: result?.isOk || false,
    message: result?.isOk ? '' : 'Помилка входу',
    data: {
      ...result,
      email,
      avatarUrl: result.avatarUrl || DEFAULT_AVATAR_URL,
    },
  }))
  .catch((e) => {
    Sentry.captureException(e);
    return ({
      isOk: false,
      message: 'Помилка входу',
    });
  });


export const createAccount = async (email, password) => {
  console.log(email, password);
  Promise.resolve({
    isOk: true,
  });
  /*
  {
    isOk: false,
    message: 'Failed to create account',
  };
  */
};


export const changePassword = async (email, recoveryCode) => {
  console.log(email, recoveryCode);
  Promise.resolve({
    isOk: true,
  });
  /*
  {
    isOk: false,
    message: 'Failed to change password',
  };
  */
};

export const resetPassword = async (email) => {
  console.log(email);
  Promise.resolve({
    isOk: true,
  });
  /*
    {
      isOk: false,
      message: 'Failed to reset password',
    };
  */
};
