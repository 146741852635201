import ExcelJS from "exceljs";

const adjustColumnWidth = (worksheet) => {
  worksheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => v.toString().length);
    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number")) + 3;
    column.width = maxLength;
  });
};

export const exportExcel = async (fileName, items) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  worksheet.getCell(1, 2).value = "Звіт про заборгованість ";

  let rNum = 3;

  //header
  worksheet.getCell(rNum, 2).value = "Контрагент";
  worksheet.getColumn(2).width = 120;
  worksheet.getCell(rNum, 3).value = "Замовл";
  worksheet.getCell(rNum, 4).value = "Акти";
  worksheet.getCell(rNum, 5).value = "Опл";
  worksheet.getCell(rNum, 6).value = "Борг";

  rNum++;

  items.forEach((r) => {
    worksheet.getCell(rNum, 2).value = r.partner;
    worksheet.getCell(rNum, 3).value = r.ord;
    worksheet.getCell(rNum, 4).value = r.d;
    worksheet.getCell(rNum, 5).value = r.k;
    worksheet.getCell(rNum, 6).value = r.rest;
    rNum++;
  });

  adjustColumnWidth(worksheet);
  return workbook.xlsx.writeBuffer();
};
