import React from 'react';
import PropTypes from 'prop-types';

export const TerminalPaymentForm = () => (
  <>Terminal</>
);

TerminalPaymentForm.propTypes = {
  data: PropTypes.object,
};

export default TerminalPaymentForm;
