import { v4 as uuid } from 'uuid';

export class DBObject {

  constructor({ class_name, ref }) {
    this.class_name = class_name;
    this.ref = ref === 'new' ? uuid() : ref;
    this.isNew = ref === 'new';
    this.to1C = false;
  }

  get _id() {
    return `${this.class_name}|${this.ref}`;
  }

  export() {
    return {
      _id: this._id,
      ref: this.ref,
      class_name: this.class_name,
      to1C: true,
    };
  }

  load(data) {
    this.ref = data.ref;
    this.isNew = false;
  }

}
