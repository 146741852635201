import dayjs from 'dayjs';
import { DBObject } from '../root/base';

const CLOSED_PERIOD_DAYS = 0;
export class Document extends DBObject {

  constructor(args) {
    super(args);
    this.data = {};
    this.date = dayjs(args.date);
    this.number_doc = args.number_doc ?? '';
  }

  export() {
    return {
      ...super.export(),
      date: this.dateTform,
      number_doc: this.number_doc,
    };
  }

  /**
   * @returns {string}
   * date converted to 'YYYY-MM-DDTHH:mm:ss'
   */
  get dateTform() {
    return this.date.format('YYYY-MM-DDTHH:mm:ss');
  }

  checkClosedPeriod(periodInDays = CLOSED_PERIOD_DAYS) {
    const today = dayjs().startOf('day');
    return this.date.add(periodInDays, 'day') < today;
  }

  async load(data) {
    super.load(data);
    this.date = dayjs(data.date);
    this.number_doc = data.number_doc;
    this.to1C = data.to1C;
  }
}
