import React, { useRef } from 'react';
import { DoclistPageTemplate } from 'components';
import { Menu } from 'devextreme-react';
import { useNavigate } from 'react-router';
import { dsEasyPayMessages } from 'datasources';
import { useDocChangeListener } from 'hooks';
import { Column, Lookup } from 'devextreme-react/data-grid';
import { statuses, types_message } from './easypay_const';

export const EasyPayList = () => {
  const gridRef = useRef();
  const navigate = useNavigate();

  useDocChangeListener('doc.easypay_message', () => {
    gridRef.current.instance.refresh(true);
  });

  return (
    <DoclistPageTemplate
      ref={gridRef}
      columnsDataFields = {{
        date: 'date',
        docNumber: 'number_doc',
      }}
      pageName='Повідомлення про інциденти EasyPay'
      dataSource={dsEasyPayMessages}
      onEdit={(ref) => navigate(`/easypaymessage/${ref}`)}
      menuComponent={
        <Menu
          onItemClick={(e) => {
            switch (e.itemData.id) {
            case 'new': {
              navigate('/easypaymessage/new');
              break;
            }
            default:
            }
          }}
          dataSource={[
            {
              text: 'Додати',
              icon: 'add',
              id: 'new',
            },
          ]}
        />
      }
    >
      <Column
        dataField='typeof_message'
        caption='Тип інциденту'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
        maxWidth={100}
      >
        <Lookup
          dataSource={types_message}
          valueExpr="code"
          displayExpr="caption"
        />
      </Column>
      <Column
        dataField = 'status'
        caption='Статус'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      >
        <Lookup
          dataSource={statuses}
          valueExpr="code"
          displayExpr="caption"
        />
      </Column>
      <Column
        dataField='description'
        caption='Опис'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      />
      <Column
        dataField='note'
        caption='Коментар'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      />
    </DoclistPageTemplate>
  );
};
