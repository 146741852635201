import React from 'react';
import { DateBox, SelectBox } from 'devextreme-react';
import PropTypes from 'prop-types';

import styles from './ask-policie.module.scss';
import dayjs from 'dayjs';

export const AskPolicy = ({
  isPolicie,
  onChangeIsPolicie,
  policieValidTo,
  onChangeValid,
}) => (
  <div className={styles.policieGroup}>
    <div className={styles.policieElement}>
      <div className={styles.policieLabel}>Є поліс ОСЦПВ</div>
      <SelectBox
        label=''
        items = {[ 'Так', 'Ні', 'Відмовився', 'Оформляємо' ]}
        placeholder="Запитайте клієнта"
        value={isPolicie}
        onSelectionChanged={(value) => onChangeIsPolicie(value.selectedItem)}
      />
    </div>
    <div className={styles.policieElement}>
      <div className={styles.policieLabel}>Термін дії</div>
      <DateBox
        value={ policieValidTo ? dayjs(policieValidTo).toDate() : undefined}
        onValueChanged={(value) => onChangeValid(value)}
      >
      </DateBox>
    </div>
  </div>
);

AskPolicy.propTypes = {
  isPolicie: PropTypes.string,
  onChangeIsPolicie: PropTypes.func,
  policieValidTo: PropTypes.any,
  onChangeValid: PropTypes.func,
};
