export const cardFields1 = [
  { text: 'Тип Полісу', data: 'policy_type' },
  { text: 'Держ.Номер ТЗ', data: 'vehicle_plate_num' },
  { text: 'Тип ТЗ', data: 'vehicle_type' },
  { text: 'Марка', data: 'vehicle_mark' },
  { text: 'Модель', data: 'vehicle_model' },
  { text: 'Рік випуску', data: 'vehicle_year' },
  { text: 'VIN Code', data: 'vehicle_vin' },
  { text: 'Поліс №', data: 'policy_number' },
  //Дата окончания действия СП  start_date end_date
  { text: 'Дійсний до/(Строк дії)', data: 'end_date' },
];
export const cardFields2 = [
  // Тип лица, которое приобретало СП: Юр.лицо / Физ.лицо
  { text: 'Тип Страхувальника', data: 'type_of_insured_person' },
  { text: 'Реєстрація', data: 'vehicle_city' },
  // ФИО лица, которое приобретало СП
  //lastname + firstname + middlename
  { text: 'Страхувальник', data: 'full_name' },
  //Документ, который был указан в качестве подтверждающего лицо при покупке СП
  // document_type + document_series + document_number
  { text: 'Документ', data: 'document_full_name' },
  //Тип оплаты: -он-лайн; -нал; -QR-Code
  { text: 'Тип оплаты', data: 'payed_type' },
  { text: 'Франшиза', data: 'selectedFranchise' },
  { text: 'Пільги', data: 'privilege' },
  // Страховая компания (СК) - эмитент полиса
  { text: 'Страховик', data: 'companie_name' }, //companie.name
  { text: 'Страховой платеж', data: 'selectedPrice' },
];
export const documents_text = {
  passport: 'Паспорт',
  id_card: 'ID карка',
  driver_license: 'Водійське посвідчення',
  chernobyl_victim: 'Посвiдчення особи постраждалої від ЧАЭС',
  invalid: 'Посвiдчення особи з iнвалiдністю ІІ групи',
  pensioner: 'Посвідчення пенсійне',
  war_participant: 'Посвiдчення учасника вiйни',
};
export const priviliges_text = {
  without: 'Без пільг',
  war_participant: 'Учасники війни',
  invalid: 'Особа з інвалідністю ІІ групи',
  chernobyl_victim: 'Особа, яка постраждала внаслідок Чорнобильської ' +
    'катастрофи, віднесена до I або II категорії',
  pensioner: 'Пенсіонери громадяни України',
};
