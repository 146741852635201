export const pNum = '^[0-9]+$'; // только числа
export const pPosNegNum = '^[+-]{0,1}[0-9]+$'; // только числа с "+"\"-"
// только числа с "+"\"-" и ","\"."
export const pPosNegNumDot = '^[+-]{0,1}[0-9]{1,10}[,.]{0,1}[0-9]{0,3}$';
export const pPosNegNumDotUndef = `${pPosNegNumDot}|^---$`; //
// максимум 3 чиcла до комы и 1 после
export const pNum_3_1 = '^[0-9]{0,3}[,.]{0,1}[0-9]{0,1}$';
// максимум 3 чиcла до комы и 2 после
export const pNum_3_2 = '^[0-9]{0,3}[,.]{0,1}[0-9]{0,2}$';
// максимум 3 числа до комы и 3 после
export const pNum_3_3 = '^[0-9]{0,3}[,.]{0,1}[0-9]{0,3}$';
// максимум 5 чисел до комы и 1 после
export const pNum_5_1 = '^[0-9]{0,5}[,.]{0,1}[0-9]{0,1}$';
// максимум 5 чисел до комы и 2 после
export const pNum_5_2 = '^[0-9]{0,5}[,.]{0,1}[0-9]{0,2}$';
// максимум 2 чисел до комы и 1 после
export const pNum_2_1 = '^[0-9]{0,2}[,.]{0,1}[0-9]{0,1}$';
// формат для теиперетури
export const pTemperature = '^[+-]{0,1}[0-9]{0,2}[,]{0,1}[0-9]{1}$';
//буквы числа + символ - "\"
export const pVINCode_1 = '^[\\а-яА-яa-zA-Z0-9]+$';
//запрещенные символы і,о,І,О
export const pVINCode_2 = '^[^!ioIOіоІО]+$';
//буквы числа
export const pTextNum = '^[а-яА-яa-zA-Z0-9]+$';
//буквы Англ. числа
export const pTextNumLat = '^[a-zA-Z0-9]+$';
//гос. номер авто
// export const pVehiclePlateNum =
//   '^([а-яА-Яa-zA-ZіІїЇєЄ]{2}[0-9]{4}[а-яА-Яa-zA-ZіІїЇєЄ]{2})|' +
//   '([0-9]{5}[а-яА-Яa-zA-ZіІїЇєЄ]{2})$';
// VIN номер ТЗ СП
export const pVehiclevVIN =
  '^[0-9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,R,S,T,U,V,W,X,Y,Z]{5,20}$';
//буквы
export const pText = '^[а-яА-Яa-zA-ZіІїЇєЄ]+$';
//
export const pEmail =
  '^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$';
//шаблон для поля document_record - "Запис документа" платіжна система
export const pDocumentRecord = '^[0-9]{8}-[0-9]{5}$';
