import React, { useRef } from 'react';
import { StickyBar, DocInfoBar, DocMenu, SingleFileUploader, DocHeader } from 'components';
import { useDocState, useDate, useProj, useLab } from 'hooks';
import dayjs from 'dayjs';
import { gqlClient } from 'gql-client';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { ColCountByScreen, EmptyItem, Form, GroupItem, Label, RequiredRule, SimpleItem } from 'devextreme-react/form';
import { useScreenSize } from 'utils/media-query';
import { NumberBox, TextBox, DateBox } from 'devextreme-react';
import styles from '../orders/order.module.scss';
import { BLANKS_BUCKET_URL, FORM_STYLING_MODE } from 'app-constants';
import { TextArea } from 'devextreme-react/text-area';
import { loader } from 'graphql.macro';
import { cloneDeep } from 'lodash';
import { v4 as uuid_v4 } from 'uuid';
import { types_message, statuses } from './easypay_const';
import { SelectBox } from 'devextreme-react/select-box';
import { useAuth } from 'contexts/auth';
import { updateBranchOptions } from 'utils/helpers';
import { Validator } from 'devextreme-react/validator';
const LabSearch = React.lazy(() => import('components/lab-search/lab-search'));

const CLASS_NAME = 'doc.easypay_message';
const getDoc = loader('./gql/getEasyPayMessage.gql');
const updateEasyPayMessage = loader('./gql/updateEasyPayMessage.gql');

export const EasyPayMessage = () => {
  const { user, options } = useAuth();
  const screenSize = useScreenSize();
  const { proj } = useProj();
  const { lab } = useLab();
  const { formatDate } = useDate();
  const [data, setData, { loading, isDocNew }] = useDocState({
    className: CLASS_NAME,
    defaultData: {
      date: dayjs().format(),
      number_doc: '',
      class_name: CLASS_NAME,
      terminal_number: options.branchOptions?.terminal_number,
      department: lab,
      status: 100,
      note: '',
    },
    load: (id) =>
      gqlClient.query({ query: getDoc, variables: { ref: id } }).then((response) => {
        const doc = response?.data?.getEasyPayMessage?.[0];
        if (!doc.ref) throw new Error();
        return doc;
      }),
    update: (state, data) => ({ ...state, ...data }),
  });

  const formGroupRef = useRef();
  const handleDocumentSave = async () => {
    let errorMessage = '';
    const validRes = formGroupRef.current.instance.validate();
    if (!validRes.isValid) {
      validRes.brokenRules.forEach((bRule) => {
        errorMessage += `${bRule.message}\r\n`;
      });
      return Promise.reject(errorMessage);
    }

    const doctosave = cloneDeep(data);
    doctosave.date = formatDate(doctosave.date);
    doctosave.department = data?.department?.ref;
    doctosave.organization = data?.organization?.ref || data?.organization;
    const saveUuid = uuid_v4();
    if (isDocNew) {
      doctosave._id = `${CLASS_NAME}|${saveUuid}`;
      doctosave.class_name = CLASS_NAME;
    }

    if (doctosave.terminal_number !== options.branchOptions?.terminal_number) {
      if (!options.branchOptions) options.branchOptions = {};
      options.branchOptions.terminal_number = doctosave.terminal_number;
      updateBranchOptions(options.branchOptions);
    }

    await gqlClient.mutate({ mutation: updateEasyPayMessage, variables: { input: doctosave } }).then((res) => {
      if (res.data?.setEasyPayMessage?._id !== 'ok') throw new Error('Помилка надсилання повіломлення');
      return res;
    });

    return Promise.resolve(doctosave.ref || saveUuid);
  };

  const docReadOnly = data.status !== 100 && !user.isAdmin;

  const changeReq = (e) => {
    setData((prevState) => ({ ...prevState, [e.element.id]: e.event?.target?.value }));
  };

  const calculateStatus = (statuscode) => statuses.find((statusrow) => statusrow.code === statuscode)?.caption || '';

  return (
    <React.Fragment>
      <StickyBar>
        <DocInfoBar
          name='Повідомлення про інцидент'
          data={{ date: data.date, number: data.number_doc }}
          loading={loading}
          isNew={isDocNew}
        >
          {data.protected && (
            <div className={'otk-tag otk-status-warning'}>
              <div className='dx-icon-warning'></div>
              &nbsp;Документ тільки для перегляду
            </div>
          )}
        </DocInfoBar>

        <DocMenu
          isDocNew={isDocNew}
          allowSaving={!docReadOnly}
          onSave={handleDocumentSave}
          printItems={
            [
              // template for later use
              // {
              //   text: 'Рахунок',
              //   // url: `${API_HOST}/printform/${id}/inv`,
              //   disabled: !data.number_doc,
              // },
            ]
          }
        />
      </StickyBar>

      <LoadPanel visible={loading} />
      <ValidationGroup ref={formGroupRef}>
        <div className='content-block otk-content-block'>
          <div className={'otk-doc-container otk-doc-form otk-doc-form-large dx-card'}>
            <Form labelLocation='top' formData={data}>
              <GroupItem>
                <ColCountByScreen xs={1} sm={3} md={3} lg={3} />
                <GroupItem colSpan={1}>
                  <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                  <SimpleItem colSpan={2} isRequired>
                    <Label text='Тип інциденту' location='left' />
                    <SelectBox
                      dataSource={types_message}
                      valueExpr='code'
                      displayExpr='caption'
                      value={data.typeof_message}
                      onSelectionChanged={(e) => {
                        setData((prev) => ({ ...prev, typeof_message: e.selectedItem.code }));
                      }}
                    >
                      <Validator>
                        <RequiredRule message='Тип інциденту не заповнений' />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>
                  <SimpleItem colSpan={2} isRequired>
                    <Label text='Номер терміналу' location='left' />
                    <TextBox
                      id='terminal_number'
                      dataSource={types_message}
                      value={data.terminal_number}
                      onChange={changeReq}
                    >
                      <Validator>
                        <RequiredRule message='Номер терміналу не заповнений' />
                      </Validator>
                    </TextBox>
                  </SimpleItem>
                  <EmptyItem colSpan={2}/>

                  <SimpleItem 
                    colSpan={2}
                    render={() => (
                        <div style={{ display: 'flex', alignItems: 'center' , height:'100%'}}>
                          <SingleFileUploader
                          width={200}
                          bucketUrl={BLANKS_BUCKET_URL}
                          uploadedFileUrl={data.printscreen}
                          onFileUploaded={(file) => setData((prev) => ({ ...prev, printscreen: file.url }))}
                          onFileDeleted={() => setData((prev) => ({ ...prev, printscreen: null }))}
                        />
                        </div>
                    )}
                  >
                    <Label text='Знімок екрану' location='left' />
                  </SimpleItem>
                </GroupItem>

                <GroupItem
                  visibleIndex={screenSize.isXSmall ? 0 : null}
                  colSpan={1}
                  cssClass={screenSize.isXSmall ? '' : styles.docInfoGroup}
                >
                  {user.isAdmin && (
                    <SimpleItem
                      cssClass={styles.orderInfoItem}
                      render={() => (
                        <div className={styles.orderInfo}>
                          <div> Модерація </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <SingleFileUploader
                              width={200}
                              bucketUrl={BLANKS_BUCKET_URL}
                              uploadedFileUrl={data?.moder_file}
                              onFileUploaded={(file) =>
                                setData((prev) => ({
                                  ...prev,
                                  moder_file: file.url,
                                }))
                              }
                              onFileDeleted={() =>
                                setData((prev) => ({
                                  ...prev,
                                  moder_file: null,
                                }))
                              }
                            />
                          </div>
                          <SelectBox
                            items={statuses.filter((el) => el?.moderated)}
                            valueExpr='code'
                            displayExpr='caption'
                            value={data.status}
                            onSelectionChanged={(e) => {
                              setData((prev) => ({ ...prev, status: e.selectedItem.code }));
                            }}
                          />
                        </div>
                      )}
                    />
                  )}
                </GroupItem>
                <GroupItem
                  visibleIndex={screenSize.isXSmall ? 0 : null}
                  colSpan={1}
                  cssClass={screenSize.isXSmall ? '' : styles.docInfoGroup}
                >
                  <SimpleItem>
                    <DocHeader
                      number_doc={data.number_doc}
                      date={data.date}
                      statusText={calculateStatus(data.status)}
                    />
                  </SimpleItem>

                  {user.isAdmin && (
                    <SimpleItem>
                      <Label text='Лабораторія' location='left' />
                      <LabSearch
                        onInput={(e) => {
                          const value = e.event?.target?.value;
                          setData((prev) => ({
                            ...prev,
                            number_doc: '',
                            department: value,
                            organization: value?.organization,
                          }));
                        }}
                        branch={data?.department || lab}
                      />
                    </SimpleItem>
                  )}
                </GroupItem>
              </GroupItem>

              {/* //--------------------- */}
              <GroupItem>
                <ColCountByScreen xs={1} sm={2} md={2} lg={2} />

                <GroupItem colSpan={2} visible={data.typeof_message === 'finance'} cssClass={styles.orderInfoItem}>
                  <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                  <SimpleItem colSpan={1}>
                    <Label text='Тип послугии' location='left' />
                    <SelectBox
                      value={data.proj}
                      dataSource={proj}
                      valueExpr='ref'
                      displayExpr='name'
                      onSelectionChanged={(e) => {
                        setData((prev) => ({ ...prev, proj: e.selectedItem.ref }));
                      }}
                    >
                      {data.typeof_message === 'finance' && (
                        <Validator>
                          <RequiredRule message='Тип послуги не заповнений' />
                        </Validator>
                      )}
                    </SelectBox>
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <Label text='Сума' location='left' />
                    <NumberBox id='amount' value={data?.amount} onChange={changeReq}>
                      {data.typeof_message === 'finance' && (
                        <Validator>
                          <RequiredRule message='Сума не вказана' />
                        </Validator>
                      )}
                    </NumberBox>
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <Label text='Дата' location='left' />
                    <DateBox
                      value={data.data_incident}
                      id='data_incident'
                      type='datetime'
                      dataSource={proj}
                      valueExpr='ref'
                      displayExpr='name'
                      onValueChange={(value) => {
                        setData((prevState) => ({
                          ...prevState,
                          data_incident: formatDate(value),
                        }));
                      }}
                    />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
              <GroupItem>
                <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
                <SimpleItem colSpan={2} isRequired>
                  <Label text='Опис' />
                  <TextArea
                    id='description'
                    readOnly={docReadOnly}
                    value={data.description}
                    stylingMode={FORM_STYLING_MODE}
                    hint='Опис інциденту'
                    onInput={changeReq}
                  >
                    <Validator>
                      <RequiredRule message='Опис інциденту не заповнений' />
                    </Validator>
                  </TextArea>
                </SimpleItem>
                <SimpleItem colSpan={2}>
                  <Label text='Коментар' />
                  <TextArea
                    id='note'
                    readOnly={docReadOnly}
                    value={data.note}
                    stylingMode={FORM_STYLING_MODE}
                    hint='коментар'
                    onInput={changeReq}
                  />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>
      </ValidationGroup>
    </React.Fragment>
  );
};
