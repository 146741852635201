import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts/index.js';
import { LoginForm } from './components/index.js';

export const UnauthenticatedContent = function () {
  return (
    <Routes>
      <Route exact path="/login" element={<SingleCard title="Вхід"><LoginForm /></SingleCard>}/>
      <Route exact path="/create-account" element={<SingleCard title="Sign Up"/>}/>
      <Route path="*" element={<Navigate to='/login' replace={true}/>} />
    </Routes>
  );
};
