import React from 'react';
import { StickyBar } from 'components';
import { manuals } from 'manuals';
import styles from './manuals.module.scss';
import { Link } from 'react-router-dom';

export const ManualMOC = () => {

  return (
    <div>
      <StickyBar>
        <div className='otk-info-bar'>Довідка</div>
      </StickyBar>

      <div className='content-block otk-content-block'>
        <div className='otk-doc-container otk-doc-form dx-card'>
          <ul>
            {manuals.map((manual) => (
              <li className={styles.mocItem} key={manual.pageId}>
                <Link className={styles.manualLink} to={`/manual/${manual.pageId}`}>{manual.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
