import { loader } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';

const dsDebits = loader('./dsDebitsByAct.gql');

export const dsDebitsByAct = createDataSource(dsDebits, {
  processData: (data) => {
    const partnersWithWarning = [];

    const newdata = data?.map((row, index) => {
      if (row.trans === null) {
        row.trans = {
          caption: '',
          doc_amount: 0,
        };
        partnersWithWarning.push(row.partner.ref);
      }
      return { ...row, ref: index };
    });

    if (partnersWithWarning.length) {
      newdata.forEach((r) => {
        if (partnersWithWarning.includes(r.partner.ref)) {
          r.partner.isWarn = true;
        }
      });
    }

    return newdata;

  },
  loadOptions: {
    take: 9999,
  },
});

dsDebitsByAct.on('loading', (options) => {
  options.userData = dsDebitsByAct?.userData;
});
