
const individualValue = 'ФизЛицо';

export const reducer = (state, { type, payload }) => {

  switch (type) {
  case 'LOADING':
    if (payload !== state.loading) {
      return { ...state, loading: payload };
    }
    break;
  case 'DEBIT':

    return { ...state, debit: payload };

  case 'UPDATE':

    return { ...state, ...payload };

  case 'ALTER_PRICE':
    state.doc.useAddPrice = payload;
    return { ...state };

  case 'UPDATE_DOC':
    if (payload.proj && payload.proj !== state.doc.proj) {
      state.doc.initServices();
    }
    if (payload.ext_json) {
      state.doc.ext_json = {
        ...state.doc.ext_json,
        ...payload.ext_json,
      };
      delete payload.ext_json;
    }
    Object.keys(payload).forEach((key) => {
      state.doc[key] = payload[key];
    });
    return { ...state };
  case 'ACTLOADER': {
    return { ...state, loaderActButton: payload };
  }
  case 'UPDATE_PARTNER': {
    const partner = payload;
    const newData = state.doc;
    newData.partner = partner;
    if (partner.individual_legal === individualValue) {
      newData.ClientPerson = partner.name ?? '';
    } else {
      newData.ClientPerson = '';
    }
    newData.ClientPersonPhone = partner.phones?.split(',')?.[0] ?? '';
    newData.contract = undefined;
    newData.isSubContract = false;
    newData.useAddPrice = false;
    newData.price_type = state.doc._lab.price_type;
    return { ...state };
  }
  default:
    break;
  }
  return state;
};

