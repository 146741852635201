import { gql } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';
import { diiaStatuses } from 'app-constants';
import dayjs from 'dayjs';


const dsReportsSearch = gql `query dsReportsSearchGetReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
  totalcount: lab_report(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }
  list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort ) {
    ref number_doc date caption gnumber
    invoice { ref caption number_doc pay_kind date}
  }
}`;

const ds = createDataSource(dsReportsSearch);

export const dsReports = (orderRef, ignoreRef) => {
  const dsProxy = createDataSource(dsReportsSearch);

  const buildFilter = (filter) => {
    let resFilter = filter;
    if (ignoreRef) {
      const refFilt = [ 'ref', '<>', ignoreRef ];
      resFilter = resFilter ? [ resFilter, 'and', refFilt ] : refFilt;
    }
    if (orderRef) {
      const refFilt = [ 'invoice', '=', orderRef ];
      resFilter = resFilter ? [ resFilter, 'and', refFilt ] :refFilt;
    }

    return resFilter;
  };

  dsProxy.load = (options) => ds.load({
      ...options,
      filter: buildFilter(options.filter),
    });

  return dsProxy;
};

export const dsReportsCancelled = (partnerRef, ignoreRef) => {
  const dsProxy = Object.create(ds);

  const buildFilterC = (filter) => {
    let resFilter = filter;
    if (ignoreRef) {
      const refFilt = [ 'ref', '<>', ignoreRef ];
      resFilter = resFilter ? [ resFilter, 'and', refFilt ] : refFilt;
    }
    if (partnerRef) {
      const pfilt = [
        [ 'partner', '=', partnerRef ],
        'and',
        [ 'status', '=', diiaStatuses.CANCELED.status ],
        'and',
        [ 'date', '>=', dayjs().add(-3, 'month').format('YYYY-MM-DD') ],
      ];
      resFilter = resFilter ? [ resFilter, 'and', pfilt ] : pfilt;
    }

    return resFilter;
  };

  dsProxy.load = (options) => ds.load({
    ...options,
    filter: buildFilterC(options.filter),
  });
  return dsProxy;
};
