import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//parts
import { Part1 } from './part1';
import { Part2 } from './part2';
import { Part3 } from './part3';
import { Part4 } from './part4';

import {
  Menu,
} from 'devextreme-react';

import styles from '../styles/style.module.scss';

import {
  DocInfoBar,
  StickyBar,
} from 'components';

import { getPrivilegesList } from '../api/requests';
import { getDocumentsFromPrivileges } from './utils';
import { useDate } from 'hooks';

//базовий компонент для сторінки покупки полісу
//покупка полісу відбувається у 4 кроки для кожного відображається відповідний
//компонент Part1-4
//більшість запитів відправляються через proxy на xsurance ../api.js
//для оплати на easypay також через proxy ../apiPayment.js

//=== env для easypay: ===
// REACT_APP_POLICY_API_EASYPAY
// REACT_APP_POLICY_API_NOTIFY
// REACT_APP_POLICY_API_PKEY_EASY
// REACT_APP_POLICY_API_SKEY

//=== env для xsurance: ===
// REACT_APP_POLICY_API_XSURANCE
// REACT_APP_POLICY_API_PKEY

//=== env загально для proxy: ===
// REACT_APP_POLICY_API_PROXY_API

export const PolicyNew = () => {
  const navigate = useNavigate();

  const [ loading, setLodading ] = useState(false);
  const { today } = useDate();

  const [ data, setData ] = useState({
    step: 1, payment_type: 'card', policy_type: 'osago',
    type_of_insured_person: 'Фіз. особа',
    privilege: 'without', franchise: 0,
    agreement: true, no_taxi: true,
    next_service_date: null,
    start_date: today.add(1, 'day'),
    period: 'year',
  });

  useEffect( () => {

    const asyncFunc = async() => {
      setLodading(true);
      const privileges = await getPrivilegesList();
      setData((prev) => ({ ...prev, privileges }));
      const documents = getDocumentsFromPrivileges(privileges);
      setData((prev) => ({ ...prev, documents }));
      setLodading(false);
    };
    asyncFunc();
  }, []);
  return (
    <div>
      <StickyBar>
        <DocInfoBar
          name='Покупка Страхового Полісу'
          loading={loading}
        />
        <Menu
          onItemClick={(e) => {
            if (e.itemData.id === 'close') {
              navigate('/policy');
            }
          }}
          dataSource={[
            {
              text: 'Закрити',
              id: 'close',
              icon: 'close',
            },
          ]}
        />
      </StickyBar>
      <div className={`${styles?.container} dx-card`}>
        <div>
          { data?.step === 1 && <Part1 data={data} setData={setData}/> }
          { data?.step === 2 && <Part2 data={data} setData={setData}/> }
          { data?.step === 3 && <Part3 data={data} setData={setData}/> }
          { data?.step === 4 && <Part4 data={data} setData={setData}/> }

        </div>
      </div>
    </div>
  );
};
