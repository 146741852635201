import React, { useState, useRef } from 'react';
// import { FileUploader } from 'devextreme-react/file-uploader';
import { Button } from 'devextreme-react';
import { v4 as uuid_v4 } from 'uuid';
import { useApolloClient, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { openDoc } from 'utils/open-doc';

import './styles.scss';
const GET_UPLOAD_URL = gql`query ($uploadFileName: String) {getNewFileUrl(fileName: $uploadFileName)}`;

export const SingleFileUploader = ({
  onFileUploaded = () => {},
  onFileDeleted = () => {},
  uploadedFileUrl = '',
  disabled = false,
  width = '100%',
  accept,
  ...props
}) => {
  // const [file, setFile] = useState([]);
  const [btnId] = useState(uniqueId('uploader-'));
  const gqlClient = useApolloClient();
  // const fileUploaderRef = useRef();

  const humanFileName =
    uploadedFileUrl?.substring?.(
      uploadedFileUrl.lastIndexOf('|') === -1 ? uploadedFileUrl.lastIndexOf('/') + 1 : uploadedFileUrl.indexOf('|') + 1
    ) || '';

  const uploadFile = async (file, progressCb) => {
    const uploadFileName = `${uuid_v4()}|${file.name}`;
    const qlResponse = await gqlClient.query({ query: GET_UPLOAD_URL, variables: { uploadFileName } });

    if (qlResponse.errors || !qlResponse.data.getNewFileUrl) return Promise.reject();

    const uploadUrl = qlResponse.data.getNewFileUrl;

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', uploadUrl, true);
      xhr.upload.onprogress = (e) => progressCb(e.loaded, e.total);
      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            file.url = props.bucketUrl + uploadFileName;
            file.newName = uploadFileName;
            resolve(file);
          } else {
            reject();
          }
        }
      };
      xhr.onerror = () => reject();
      xhr.send(file);
    });
  };

  // const removeFileAfter = (delay = 0) => {
  //   setTimeout(() => setFile([]), delay);
  // };

  const inputFile = useRef(null);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    const filesArray = Array.from(files);
    // setFile(filesArray);
    if (filesArray.length)
      uploadFile(filesArray[0], () => {}).then((file) => {
        onFileUploaded(file);
      });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width }}>
        <Button
          id={btnId}
          // className='single-file-uploader-folder-button'
          icon='folder'
          disabled={disabled}
          hint={uploadedFileUrl ? 'змінити файл' : 'вибрати файл'}
          onClick={() => inputFile.current.click()}
        />
        {uploadedFileUrl ? (
          <div>
            <Button
              // className='single-file-uploader-remove-button'
              // title='видалити файл'
              disabled={disabled}
              icon='remove'
              onClick={(e) => {
                if (uploadedFileUrl) onFileDeleted(e);
              }}
            />
            <a  //className='single-file-uploader-link'
                // title={humanFileName}
                href={uploadedFileUrl}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => openDoc(uploadedFileUrl)}
              >
                {humanFileName}
              </a>
          </div>
        ) : (
          <div className='single-file-uploader-nofile-text'>
            <span>файл не завантажений</span>
          </div>
        )}
      </div>
      <input
        // style={{ display: 'none' }}
        style={{ visibility: 'hidden', width: 0, height: '32px' }}
        accept={accept}
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
    </div>
  );
};

SingleFileUploader.propTypes = {
  onFileUploaded: PropTypes.func,
  onFileDeleted: PropTypes.func,
  uploadedFileUrl: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bucketUrl: PropTypes.string.isRequired,
};
