import { DateRange, PageContent } from 'components';
import { DataGrid, Form, SelectBox } from 'devextreme-react';
import { DOCLIST_PAGE_SIZE, DX_DATE_DISPLAY_FORMAT, FORM_STYLING_MODE } from 'app-constants';
import { ButtonItem, GroupItem, SimpleItem } from 'devextreme-react/form';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { showWarning } from 'utils/notify';
import { useDate, usePayKinds } from 'hooks';
import { useAuth } from 'contexts/auth';
import { Column, Paging, Scrolling } from 'devextreme-react/data-grid';
import styles from './payReport.module.scss';
import { Link } from 'react-router-dom';
import { dsPays } from './dsPays';

const labels = {
  dateRange: { text: 'Виберіть діапазон дат' },
  statuses: { text: 'Статус' },
  payKinds: { text: 'Методи оплати' },
};

const payKindsStatuses = [
  { value: 0, text: 'Неоплачені' },
  { value: 1, text: 'Оплачені' },
];

const checkInterval = (start, end, monthes = 3.0) => dayjs(end).diff(start, 'month', true) < monthes;

const applyFilters = (filters, options, gridRef) => {
  dsPays.userData = {
    userVars: {
      start_date: filters.date.start,
      end_date: filters.date.end,
      payKind: filters.payKind ?? options.easyPay.teminalUUID,
      status: filters.status ?? 0,
    },
  };
  gridRef.current.instance?.refresh();
};

export const PayReport = () => {
  const { options, lab } = useAuth();
  const { startOfDay, endOfDay } = useDate();
  const [filters, setFilters] = useState({
    date: {
      start: startOfDay(),
      end: endOfDay(),
    },
    payKind: options.easyPay.teminalUUID,
    status: 0,
  });

  dsPays.userData = {
    userVars: {
      start_date: filters.date.start,
      end_date: filters.date.end,
      payKind: filters.payKind ?? options.easyPay.teminalUUID,
      status: filters.status ?? 0,
    },
  };

  const [payKindsList, setPayKindsList] = useState([]);
  const { payKinds, availablePayKinds } = usePayKinds();
  const gridRef = useRef();

  useEffect(() => {
    const { teminalUUID, partUUID, cardUUID } = options.easyPay;
    const payKindsList = availablePayKinds(lab).filter(
      (r) => [cardUUID, teminalUUID, partUUID].includes(r.ref) && !r.disabled
    );
    if (payKindsList.length) setPayKindsList(payKindsList);
  }, [lab, payKinds, availablePayKinds, options.easyPay]);

  useEffect(() => applyFilters(filters, options, gridRef), [filters, options]);

  const orderCell = useCallback(({ data }) => <Link to={`/order/${data.data.ref}`}>{data.displayValue}</Link>, []);

  return (
    <PageContent size='large'>
      <div className='otk-page-header'>Звіт про оплати в терміналі та он-лайн</div>
      <Form stylingMode={FORM_STYLING_MODE}>
        <GroupItem colCount={4}>
          <SimpleItem label={labels.dateRange}>
            <DateRange
              startValue={filters.date.start}
              endValue={filters.date.end}
              stylingMode={FORM_STYLING_MODE}
              onRangeChanged={(range) => {
                if (!checkInterval(range.start, range.end, 3)) showWarning('Період більше 3 місяцв');
                range.start = startOfDay(range.start);
                range.end = endOfDay(range.end);
                setFilters((prev) => ({ ...prev, date: range }));
              }}
              displayFormat={DX_DATE_DISPLAY_FORMAT}
              width='100%'
            />
          </SimpleItem>
          <SimpleItem label={labels.payKinds}>
            <SelectBox
              stylingMode={FORM_STYLING_MODE}
              items={payKindsList}
              valueExpr='ref'
              displayExpr='name'
              defaultValue={options.easyPay.teminalUUID}
              onValueChanged={(value) => setFilters((prev) => ({ ...prev, payKind: value.value }))}
            />
          </SimpleItem>
          <SimpleItem label={labels.statuses}>
            <SelectBox
              stylingMode={FORM_STYLING_MODE}
              items={payKindsStatuses}
              valueExpr='value'
              displayExpr='text'
              defaultValue={0}
              onValueChanged={(value) => setFilters((prev) => ({ ...prev, status: value.value }))}
            />
          </SimpleItem>
          <ButtonItem
            buttonOptions={{
              disabled: !checkInterval(filters.date.start, filters.date.end),
              text: 'Cформувати',
              icon: 'search',
              type: 'default',
              stylingMode: 'outlined',
              onClick: () => applyFilters(filters, options, gridRef),
            }}
            verticalAlignment='center'
            horizontalAlignment='center'
          />
        </GroupItem>
        <GroupItem cssClass={styles.dataGridItem}></GroupItem>
      </Form>
      <DataGrid
        ref={gridRef}
        className={styles.dataGrid}
        showBorders
        dataSource={dsPays}
        remoteOperations
        columnAutoWidth
      >
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Paging pageSize={DOCLIST_PAGE_SIZE} />
        <Column
          dataField='number_doc'
          caption='Замовлення'
          dataType='string'
          alignment='left'
          cellComponent={orderCell}
        />
        <Column dataField='date' caption='Дата' dataType='date' alignment='center' />
        <Column dataField='partner.name' caption='Контрагент' dataType='string' alignment='left' width='50%' />
        <Column dataField='proj.name' caption='Напрямок' dataType='string' alignment='left' />
        <Column dataField='amount' caption='Сума (зам)' alignment='right' />
        <Column dataField='pay_amount' caption='Сума (опл)' alignment='right' />
        <Column dataField='diff' caption='Борг' alignment='right' />
      </DataGrid>
    </PageContent>
  );
};
