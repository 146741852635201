import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/style.module.scss';
export const TextItem = ({ text = '', data = '' }) => (
  <div className={styles?.text_item_wrap}>
    <span>{text ? `${text} :` : '' }</span>
    <span>{data}</span>
  </div>
);

TextItem.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  text: PropTypes.string,
};

export default TextItem;

