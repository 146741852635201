import {
  DATE_FORMAT,
  DX_DATE_DISPLAY_FORMAT, FORM_STYLING_MODE, orderDocStatuses,
} from 'app-constants';
import { DateRange, PageContent, statusCellRender } from 'components';
import { DataGrid, Form, TagBox } from 'devextreme-react';
import { ButtonItem, GroupItem, SimpleItem } from 'devextreme-react/form';
import React, { useMemo, useRef, useState } from 'react';
import { dsOrders } from 'datasources';
import { usePayKinds } from 'hooks';
import {
  Column, Summary, TotalItem,
} from 'devextreme-react/data-grid';
import styles from './cash-flow.module.scss';
import { exportExcel } from 'utils/export-excel';
import { find, isEqual } from 'lodash';
import dayjs from 'dayjs';
import { showWarning } from 'utils/notify';
import { createMultiTagHandler } from 'utils/helpers';


const orderDocStatusValues = Object.values(orderDocStatuses);

const labels = {
  dateRange: { text: 'Виберіть діапазон дат' },
  statuses: { text: 'Статуси' },
  payKinds: { text: 'Методи оплати' },
};

const checkInterval = (start, end) => dayjs(end)
  .diff(start, 'month', true) <= 3.0;

const buildFilterValue = (filters) => {

  const start = dayjs(filters.date.start).startOf('day').format(DATE_FORMAT);
  const end = dayjs(filters.date.end).endOf('day').format(DATE_FORMAT);

  const dateFilter = [ [ 'date', '>', start ], 'and', [ 'date', '<', end ] ];
  if (filters.payKinds.length) {
    dateFilter.push('and');
    const payArray = [];
    filters.payKinds.forEach((pkr) => {
      payArray.push(`'${pkr.ref}'`);
    });
    dateFilter.push([ 'pay_kind', 'in', payArray ]);
  }
  return dateFilter;
};

export const CashFlowReport = () => {
  const [ showDataGrid, setShowDataGrid ] = useState(false);
  const [ filters, setFilters ] = useState({
    date: {
      start: dayjs().format(DATE_FORMAT),
      end: dayjs().format(DATE_FORMAT),
    },
    statuses: [],
    payKinds: [],
  });

  const [ data, setData ] = useState([]);
  const { payKinds } = usePayKinds();
  const dgRef = useRef();

  const applyFilters = () => {
    if (!showDataGrid) setShowDataGrid(true);
    dsOrders.load({
      filter: buildFilterValue(filters),
      take: 9999,
    }).then((res) => {
      if (filters.statuses.length) {
        const fArray = res.data.filter(
          (r) => find(filters.statuses, (s) => isEqual(s, r.status)));
        setData(fArray);
      } else {
        setData(res.data);
      }
    });
  };

  const statusTagBox = useMemo(() => {
    const maxDisplayedTags = orderDocStatusValues.length - 1;
    return <TagBox
      dataSource={orderDocStatusValues}
      displayExpr='text'
      showSelectionControls
      itemRender={(data) => (
        <div className={`otk-tag otk-status-${data.status}`}>
          {data.text}
        </div>
      )}
      maxDisplayedTags={maxDisplayedTags}
      stylingMode={FORM_STYLING_MODE}
      onMultiTagPreparing={createMultiTagHandler(maxDisplayedTags)}
      onValueChanged={(e) => {
        setFilters((prev) => ({ ...prev, statuses: e.value }));
      }}
      value = {filters.statuses}
    />;
  }, [ filters.statuses ]);


  const payKindTagBox = useMemo(() => {
    const payKindsList = payKinds ?? [];
    const maxDisplayedTags = payKindsList.length - 1;
    return <TagBox
      dataSource={payKindsList}
      displayExpr='name'
      showSelectionControls
      maxDisplayedTags={maxDisplayedTags}
      stylingMode={FORM_STYLING_MODE}
      onMultiTagPreparing={createMultiTagHandler(maxDisplayedTags)}
      onValueChanged={(e) => {
        setFilters((prev) => ({ ...prev, payKinds: e.value }));
      }}
      value={filters.payKinds}
    />;
  }, [ filters.payKinds, payKinds ]);

  return (
    <PageContent size='large'>
      <div className='otk-page-header'>звіт про рух грошових коштів</div>
      <Form
        stylingMode={FORM_STYLING_MODE}
      >
        <GroupItem colCount={4}>
          <SimpleItem label={labels.dateRange}>
            <DateRange
              startValue={filters.date.start}
              endValue={filters.date.end}
              stylingMode={FORM_STYLING_MODE}
              onRangeChanged={(range) => {
                if (!checkInterval(range.start, range.end)) {
                  showWarning('Період більше трьох місяців');
                }
                setFilters((prev) => ({ ...prev, date: range }));
              }}
              displayFormat={DX_DATE_DISPLAY_FORMAT}
              width='100%'
            />
          </SimpleItem>
          <SimpleItem label={labels.statuses}>
            {statusTagBox}
          </SimpleItem>
          <SimpleItem label={labels.payKinds}>
            {payKindTagBox}
          </SimpleItem>
          <ButtonItem
            buttonOptions={{
              disabled: !checkInterval(filters.date.start, filters.date.end),
              text: 'Cформувати',
              icon: 'search',
              type: 'default',
              stylingMode: 'outlined',
              onClick: applyFilters,
            }}
            verticalAlignment='center'
            horizontalAlignment='center'
          />
        </GroupItem>
        <GroupItem cssClass={styles.dataGridItem} visible={showDataGrid}>
          {showDataGrid && <DataGrid
            ref={dgRef}
            className={styles.dataGrid}
            showBorders
            dataSource={data}
            columnAutoWidth
          >
            <Column
              dataField='date'
              caption='Дата'
              dataType='date'
              alignment='center'
            />
            <Column
              dataField='number_doc'
              caption='Замовлення #'
              dataType='string'
              alignment='center'
            />
            <Column
              cssClass='otk-cell-no-padding'
              dataField='status'
              caption='Статус'
              dataType='string'
              alignment='center'
              calculateDisplayValue={(data) => data?.status.text || ''}
              cellRender={
                (data) => statusCellRender(data, null, (s) => s.status)
              }
            />
            <Column
              cssClass={styles.partnerColumn}
              dataField='partner.name'
              caption='Замовник'
              dataType='string'
              alignment='left'
            />
            <Column
              dataField='pay_kind'
              caption='Метод оплати'
              dataType='string'
              alignment='center'
              calculateDisplayValue={(data) => (
                payKinds.find((pk) => pk?.ref === data?.pay_kind)?.name ?? ''
              )}
            />
            <Column
              dataField='doc_amount'
              caption='Сума'
              dataType='number'
              alignment='right'
              calculateDisplayValue={(data) => `${data?.doc_amount || 0} UAH`}
            />
            <Summary>
              <TotalItem
                column='doc_amount'
                summaryType='sum'
                valueFormat={(value) => value.toFixed(2)}
                displayFormat='Всього: {0} UAH'
              />
            </Summary>
          </DataGrid>
          }
        </GroupItem>
        <GroupItem visible={showDataGrid}>
          <ButtonItem
            buttonOptions={{
              text: 'Експорт',
              icon: 'exportxlsx',
              type: 'default',
              onClick: () => {
                exportExcel('123123', dgRef.current.instance);
              },
            }}
            verticalAlignment='center'
            horizontalAlignment='right'
          />
        </GroupItem>
      </Form>
    </PageContent>
  );
};
