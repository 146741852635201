import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectBox } from 'devextreme-react/ui/select-box';
import { useAuth } from 'contexts/auth';

export const SelectProject = ({ value, items, onValueChange, ...rest }) => {
  const { projects } = useAuth();
  const [ _proj, setProj ] = useState(value);
  useEffect(() => {
    setProj(value);
  }, [ value ]);

  const handleValueChange = (e) => {
    onValueChange(e);
    setProj(e);
  };

  return (
    <SelectBox
      {...rest}
      items={items}
      displayExpr={(e) => e?.name || projects.nameByRef(value)}
      valueExpr='ref'
      onValueChange={handleValueChange}
      value={_proj}
      placeholder=''
      hint='тип замовлення'
      label='Тип замовлення'
    />
  );
};
SelectProject.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
};
