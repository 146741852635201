import { DOCLIST_PAGE_SIZE } from 'app-constants';
import CustomStore from 'devextreme/data/custom_store';
import { gqlClient } from 'gql-client';
import { filterObj } from './filtfunc';
import { splitId } from './helpers';

export const checkResponse = (response) => {
  if (response?.errors) throw new Error(response.errors?.[0]?.message ?? '');
  return response;
};

export const createDataSource = (gqlQuery, {
  processData,
  byKeyGqlQuery,
  listGqlQuery,
  rawmode,
  loadOptions,
} = {}) => {

  const getByKey = (key) => gqlClient.query({
    query: byKeyGqlQuery ?? gqlQuery,
    variables: { ref: key, requireTotalCount: false},
  })
    .then(checkResponse)
    .then(({data:{list}}) => {
      if (list?.length > 0) {
        const resp = list[0];
        if (!resp.ref && resp?._id) resp.ref = splitId(resp?._id).ref;
        return resp;
      }
      throw new Error('Empty data');
    });

  const getList = async(options) => {
    const filter = options.searchExpr && options.searchValue !== null
      ? [ options.searchExpr, options.searchOperation, options.searchValue ]
      : options.filter;
    const filterParam = filter ? filterObj(filter):null;  
    const vars = {
      ...options?.userData?.userVars,
      requireTotalCount: !!options?.requireTotalCount,
      jfilt: filterParam,
      offset: options.skip,
      limit: options.take || loadOptions?.take || DOCLIST_PAGE_SIZE,
      sort: options.sort ? {
        selector: options.sort[0].selector,
        desc: options.sort[0].desc.toString(),
      } : undefined,
      userData: options?.userData,
    };
  return gqlClient.query({
      query: listGqlQuery ?? gqlQuery,
      variables: vars
    })
    .then(resp=>checkResponse(resp).data)
    .then (({list,totalcount})=> ({
        data: processData?.(list) ?? list,
        totalCount: totalcount?.[0]?.totalcount
      }))
    .catch(e=> console('err:',e));
  };

  return new CustomStore({
    key: 'ref',
    byKey: (key) => getByKey(key),
    load: (options) => getList(options),
    rawmode,
    loadOptions,
  });
};
