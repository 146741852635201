import React from 'react';
import PropTypes from 'prop-types';
//components
import { FormField } from 'components/form-field/form-field';

export const AutoShortInfo = (props) => {
  const { data = {} } = props;
  return (<>
    <FormField textWidth={'300px'} text={'Марка + Модель + Держ.Номер: '}>
      <span>{data?.vehicle_mark} {data?.vehicle_model} {
        data?.vehicle_plate_num}</span>
    </FormField>
    <FormField textWidth={'300px'} text={'Тип ТЗ + Vin Code: '}>
      <span>{data?.vehicle_type} {data?.vehicle_vin}</span>
    </FormField>
    <br />
  </>);
};
AutoShortInfo.propTypes = {
  data: PropTypes.object,
};

export default AutoShortInfo;
