import React from 'react';
import PropTypes from 'prop-types';
import { DateBox } from 'devextreme-react';
import styles from './date-range.module.scss';
import { useDate } from 'hooks';

export const DateRange = ({
  startValue, endValue, onRangeChanged, width, ...props
}) => {
  const { formatDate } = useDate();
  return (
    <div className={styles.container} style={{ width }}>
      <DateBox
        {...props}
        value={startValue}
        onValueChanged={(e) => onRangeChanged({
          start: formatDate(e.value),
          end: endValue,
        })}
        max={endValue}
        width='100%'
      />
      <div className={styles.delimiter}>по</div>
      <DateBox
        {...props}
        value={endValue}
        onValueChanged={(e) => onRangeChanged({
          start: startValue,
          end: formatDate(e.value),
        })}
        onValueChange={(e) => console.log(e)}
        min={startValue}
        width='100%'
      />
    </div>
  );
};

const rangeType = PropTypes.shape({
  value: PropTypes.number,
  unit: PropTypes.oneOf([ 'day', 'month', 'year' ]),
});

DateRange.propTypes = {
  startValue: PropTypes.string,
  endValue: PropTypes.string,
  rangeConfig: PropTypes.shape({
    minValue: PropTypes.string,
    maxValue: PropTypes.string,
    maxRange: rangeType,
    minRange: rangeType,
  }),
  onRangeChanged: PropTypes.func,
  ...DateBox.propTypes,
};
