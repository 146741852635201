import { gqlClient } from "gql-client.js";
import { gql } from "graphql.macro"
import { checkResponse } from "utils/gql-datasource.js";

const ordersForSeletQuery = gql`query getBuyersOrderForPay($refs:[String], $proj:String, $withTerminal:Boolean) {
  getBuyersOrderForPay(refs: $refs, proj:$proj, withTerminal:$withTerminal) {
    ref number_doc date doc_amount caption pay_kind note 
     organization {ref name}
     department {ref organization name}
     services { row quantity }
     partner {  name }
  }
}`

export const getOrdersForSelect = async(refs, proj, withTerminal=false)=>{
  
  const vars = { refs, proj, withTerminal };

  return gqlClient.query({ query: ordersForSeletQuery, variables: vars })
  .then(resp=>checkResponse(resp).data)
  .then (({getBuyersOrderForPay})=> ( getBuyersOrderForPay ))
  .catch(e=> console('err:',e));
};
