import React, { useState, createContext, useContext } from 'react';


const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

const NavigationProvider = (props) => {
  const [ navigationData, setNavigationData ] = useState({});

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
};

 
function withNavigationWatcher(Component) {
  // eslint-disable-next-line react/display-name
  return function(props) {
     
    // const { path } = props.match;
    // const { setNavigationData } = useNavigation();

    // useEffect(() => {
    //   setNavigationData({ currentPath: path });
    // }, [ path, setNavigationData ]);

    return React.createElement(Component, props);
  };
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher,
};
