import { removeEmpty } from 'utils/filtfunc';
import { loader } from 'graphql.macro';
import { gqlClient } from 'gql-client';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'app-constants';

export const writeAppointment = (data) => {
  const doctosave = removeEmpty({
    ...data,
    startDate: dayjs(data.startDate).format(DATE_FORMAT),
    endDate: dayjs(data.endDate).format(DATE_FORMAT)
  });

  const setAppointment = loader('./setAppoint.graphql');
  return gqlClient.mutate({
    mutation: setAppointment,
    variables: { input: doctosave },
  });
};
