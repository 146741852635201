import React, { useEffect } from 'react';
import { useMessageVars, usePrevValue } from 'hooks';
import { differenceBy } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const NotificationManager = () => {
  const navigate = useNavigate();
  const [ newOrderList ] = useMessageVars([ 'newOrdersList' ]);
  const prevNewOrderList = usePrevValue(newOrderList);

  useEffect(() => {
    const filterList = newOrderList?.filter(r => r.class_name === 'doc.buyers_order') ?? [];
    const newOrders = differenceBy(filterList, prevNewOrderList, 'id');
    const newOrdersCount = newOrders.length;

    if (newOrdersCount > 0) {
      toast.info(
        <>
          <span className='otk-notification-main-msg'>
            {newOrdersCount === 1 ? 'Нове замовлення' : `${newOrdersCount} нових замовлення.` }
          </span>
          <br />
          <span>Натисніть щоб подивитися.</span>
        </>,
        {
          onClick: () => {
            if (newOrdersCount === 1) {
              navigate(`/order/${newOrders[0]?.ref}`);
            } else {
              navigate('/orders?show_new=true');
            }
          },
          autoClose: true,
          hideProgressBar: true,
        },
      );
    }
  }, [ newOrderList, navigate, prevNewOrderList ]);

  return null;
};
