import React from 'react';
import { Button } from 'devextreme-react/button';
import { useNavigate } from 'react-router-dom';
import styles from './manual-button.module.scss';

export const ManualButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      className={styles.button}
      focusStateEnabled={false}
      icon='help'
      hint={'Iнструкцiя'}
      onClick={() => navigate('/manuals-moc')}
    />
  );
};
