import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useSubscription } from '@apollo/client';
import { savePolicyData } from './utils';
import { getPayment } from '../card-policy/getPolicy';
import dayjs from 'dayjs';

//components
import { FormField } from 'components/form-field/form-field';
import { TextLine } from 'pages/ep-main/components/text-line';
import { LoadIndicator, Button, RadioGroup } from 'devextreme-react';
//payment forms
import { CardPaymentForm } from './payment_forms/card';
import { QRCodePaymentForm } from './payment_forms/qr_code';
import { TerminalPaymentForm } from './payment_forms/terminal';

import styles from '../styles/style.module.scss';
import { gqlClient } from 'gql-client.js';
import ByLinkPaymentForm from './payment_forms/ByLink.jsx';

const EASYPAY_CALLBACK = gql`
  subscription OnEasyPayCallBack($orderId: String!) {
    extCallBack(orderId: $orderId)
  }`;

const dataSource = [
  { title: 'Картка', value: 'card' },
  { title: 'QR-code', value: 'qr' },
  { title: 'Терминал', value: 'terminal' },
  { title: 'SMS, Viber, Email', value: 'link' },
];

//hook detect After init render
const useDidMount = () => {
  const [ didMount, setDidMount ] = useState(false);
  useEffect(() => setDidMount(true), []);
  return didMount;
};

export const Part4 = (props) => {
  const navigate = useNavigate();
  const { setData = () => {}, data = {} } = props;
  const [ loading, setLoading ] = useState(false);
  const didMount = useDidMount();

  const subscription = useSubscription(EASYPAY_CALLBACK,
    { variables: { orderId: data?.ref || '5' } });

  //збереження данив в базі
  const handleSavePolicy = useCallback(async () => {
    setLoading(true);
    await savePolicyData({ gqlClient, data });
    setLoading(false);
  }, [data]);

  //перевірка даних про оплату
  const handleGetPaymentInfo = () => {
    getPayment({ ref: data?.ref, gqlClient })
      .then(async (res) => {
        if (res) {
          setData((prev) => ({
            ...prev,
            policy_easyPay: res,
          }));
        }
      });
  };

  useEffect(() => {
    if (subscription.data?.extCallBack && !subscription.loading) {
      setData((prev) => ({
        ...prev,
        policy_easyPay: subscription.data.extCallBack,
      }));
    }
  }, [setData, subscription.data, subscription.loading]);

  //+++ 2023-05-17 фунціонал перенесено на сторону back-end
  //відправляєм конфірм проплати коли змнились дані для EasyPay callback

  useEffect(() => {
    if (didMount) handleSavePolicy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data.policy_confirm?.id, data.payment_type ]);

  const handleSetPaymentType = (value) => {
    setData((prev) => ({ ...prev, payment_type: value }));
  };

  // useEffect(() => {
  //   if (didMount) handleSavePolicy();
  // }, [data.payment_type, didMount, handleSavePolicy]);

  useEffect(() => {
    if (!didMount) setData((prev) => ({ ...prev, payment_type: 'card' }));
  }, [didMount, setData]);

  return (<>
    <h3>Крок 4. Оплата</h3>
    <>
      <TextLine />

      <FormField textWidth={'220px'} text={'Зарезервовано: '} fluid={true}>
        <div className={styles.formElementDiv}>
          <div style={{ width: '100%' }}>
            <FormField textWidth={'150px'} text={'Дата/час:'}>
              {dayjs(data?.policy_reserve?.policy_created_at)
                .format('DD.MM.YYYY HH:mm:ss')}
            </FormField>
            <FormField textWidth={'150px'} text={'Статус:'}>
              {data?.policy_reserve?.policy_status}
            </FormField>
            <FormField textWidth={'150px'} text={'Номер полісу:'}>
              {data?.policy_reserve?.policy_number}
            </FormField>
          </div>
          {/* посилання на поліс в базі МТСБУ */}
          <div className={styles.buttonsdiv}>
            <a
              href={data?.policy_reserve?.policy_direct_link}
              target='_blank' rel="noreferrer"
            >
              <img width='180' alt=''
                className={styles.image_button}
                src='https://mtsbu.ua/_i/logo.png'
              />
            </a>
            <br />
            {/* оплата на сайті easypay */}
            {data?.policy_payment?.forwardUrl &&
            <a href={data?.policy_payment?.forwardUrl}
              rel="noreferrer" target='_blank'
            >
              <img width='180' height='64' alt=''
                className={styles.image_button}
                src='https://cdn.easypay.ua/images/svg/logo.svg'
              />
            </a>}
          </div>
        </div>
      </FormField>
    </>
    <TextLine />
    <FormField textWidth={'220px'} text={'Тип оплати: '}>
      <RadioGroup
        dataSource={dataSource}
        layout="horizontal"
        onValueChanged={(e) => handleSetPaymentType(e?.value)}
        displayExpr="title"
        valueExpr="value"
        value={data.payment_type}
      />
    </FormField>
    <TextLine />
    {/*форми проплати відображаються залежно від обраного типу
    усі в папці ./payment_forms*/}
    { data?.payment_type === 'card' && <CardPaymentForm data={data} /> }
    { data?.payment_type === 'qr' && <QRCodePaymentForm data={data} /> }
    { data?.payment_type === 'terminal' && <TerminalPaymentForm data={data} /> }
    { data?.payment_type === 'link' && <ByLinkPaymentForm data={data} /> }
    
    <TextLine />
    <FormField textWidth={'220px'} text={' '}>
      <div className={styles?.res_title}>
        <Button className={styles?.mr_40}
          onClick={() => setData((prev) => ({ ...prev, step: 3 }))}>
          {'< Назад'}
        </Button>
        <Button onClick={handleSavePolicy} disabled={loading}
          className={styles?.mr_60}>Зберегти дані</Button>
        {data?.policy_confirm?.policy_status === 'confirmed' &&
          <Button onClick={() => navigate(`/policy/${data?.ref}`)}
            disabled={loading} className={styles?.mr_60}
          >Перейди до полісу</Button>
        }
        {data?.policy_confirm?.policy_status !== 'confirmed' &&
          <Button
            onClick={handleGetPaymentInfo}
            disabled={loading}
            className={styles?.mr_60}
            type='danger'
          >
            Перевірити оплату
          </Button>
        }
        {loading &&
          <LoadIndicator
            className={styles?.res_loader}
            indicatorSrc='img/loader.gif'
            width={50}
            height={50}
          />
        }
      </div>
    </FormField>
    <br />
  </>);
};

Part4.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
};

export default Part4;
