import { DateRange, PageContent } from 'components';
import {
  ButtonItem,
  EmptyItem,
  Form,
  GroupItem,
  SimpleItem,
} from 'devextreme-react/form';
import {
  DATE_FORMAT,
  DX_DATE_DISPLAY_FORMAT,
  FORM_STYLING_MODE,
  FORM_STYLING_MODE_UNDER,
} from 'app-constants';
import dayjs from 'dayjs';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, DataGrid, TextBox, Validator } from 'devextreme-react';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';

import { showError, showSuccess, showWarning } from 'utils/notify';
import { usePayKinds, useDate } from 'hooks';
import styles from './labreport.module.scss';
import { dsLabReportsFull } from 'datasources';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { gqlClient } from 'gql-client';
import { gql } from '@apollo/client';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from 'devextreme-react/load-panel';
import * as Sentry from '@sentry/react';
import { useAuth } from 'contexts/auth';
import { updateBranchOptions } from 'utils/helpers';
import { EmailRule } from 'devextreme-react/validator';

const notifySuccesEmailText = 'Звіт надіслано.';
const checkInterval = (start, end) => dayjs(end).diff(start, 'month', true) <= 1.0;

const labels = {
  dateRange: { text: 'Виберіть діапазон дат' },
  mailcc: 'Копія на email:',
  tableHeads: {
    vehicle_map: 'Відповідність',
    number_doc: '№ документа',
    blank: 'Бланк',
    status: 'Статус',
    date: 'Дата',
    time: 'Час',
    vehicle_model: {
      name: 'Модель',
      type: {
        name: 'Тип ТЗ',
      },
      brand: {
        name: 'Марка',
      },
    },
    service: {
      name: 'Категорія',
    },
    gnumber: 'Держ.номер',
    partner_name: 'Замовник',
    individual_legal: 'Тип власника',
    pay_kind: 'Тип Опл',
    amount: 'Сума',
    rp: 'Термінал',
    rv: 'РВ',
    order: 'Замовлення',
  },
};

const buildFilterValue = (filters) => {
  const start = dayjs(filters.date.start).startOf('day').format(DATE_FORMAT);
  const end = dayjs(filters.date.end).endOf('day').format(DATE_FORMAT);
  return [
    [ [ 'date', '>', start ], 'and', [ 'date', '<', end ] ],
    'or',
    [ [ 'ext_json.dateUpdate', '>', start ], 'and', [ 'ext_json.dateUpdate', '<', end ] ],
  ];
};

const mapNoms = new Map();

export const DayLabReport = () => {
  const { start, end } = useParams();
  const navigate = useNavigate();
  const { startOfDay, endOfDay, displayDateTime } = useDate();
  const [ showDataGrid, setShowDataGrid ] = useState(false);
  const [ loadingVisible, setLoadingVisible ] = useState(false);
  const [ totals, setTotals ] = useState({});
  const [ filters, setFilters ] = useState({
    date: {
      start: startOfDay(start),
      end: endOfDay(end),
    },
  });
  const {
    lab,
    options: { labReports: gOptions, easyPay: easyPayOptions, branchOptions },
  } = useAuth();

  const { payKindByRef } = usePayKinds();
  const [ data, setData ] = useState([]);
  const dgRef = useRef();
  const validatorRef = useRef(null);

  useEffect(() => {
    mapNoms.clear();
    gOptions.nomChangeLabReport.forEach((r) => mapNoms.set(r.inNom.ref, r.outNom));
  }, [ gOptions ]);

  const exportExcelBuffer = async (fileName, dataGridInstance) => {
    const { Workbook } = await import('exceljs');
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(fileName);
    let cell = worksheet.getCell(1, 2);
    cell.value = `Звіт лабораторії ${lab.lab_number} за період`;
    cell = worksheet.getCell(2, 2);
    cell.value = ` з ${displayDateTime(start)} по ${displayDateTime(end)}`;

    dataGridInstance.columnOption('TypeName', 'visible', true);
    dataGridInstance.columnOption('BrandName', 'visible', true);
    dataGridInstance.columnOption('ModelName', 'visible', true);
    return exportDataGrid({
      component: dataGridInstance,
      worksheet,
      topLeftCell: { row: 4, column: 1 },
      customizeCell: (options) => {
        if (
          options.gridCell.rowType !== 'header' &&
          options.excelCell.col === 1
        ) {
          options.excelCell.value = options.excelCell.value ? '\u{2705}' : '';
        }
      },
    }).then(() => {
      dataGridInstance.columnOption('TypeName', 'visible', false);
      dataGridInstance.columnOption('BrandName', 'visible', false);
      dataGridInstance.columnOption('ModelName', 'visible', false);
      return workbook.xlsx.writeBuffer();
    });
  };

  const inDateRange = useCallback((date) => {
    const { start, end } = filters.date;
    return date >= start && date <= end;
  },[filters.date]);

  const applyFilters = useCallback( async () => {
    setShowDataGrid(false);
    setLoadingVisible(true);
    dsLabReportsFull
      .load({
        filter: buildFilterValue(filters),
        sort: [ { selector: 'date', desc: false } ],
        take: 9999,
      })
      .then(({ data }) => {
        const _totals = { Каса: 0 };
        const {partUUID, partValue, teminalUUID} = easyPayOptions;
        data.forEach((r) => {
          r.individual_legal =
            r.partner?.individual_legal === 'ЮрЛицо' ? 'Юр' : 'Фіз';
          r.time = dayjs(r.date).format('HH:mm:ss');
          r.blank = `${r.blank_series ?? '-'} ${
            r.blank_number ? String(r.blank_number).padStart(6, '0') : ''
          }`;
          r.pay_kind = payKindByRef(r?.invoice?.pay_kind)?.name ?? '';
          r.partner_name = r.partner?.name ?? '';
          r.invoiceRef = r.invoice?.ref;
          r.invoiceNumberDoc = r.invoice?.number_doc;
          if (mapNoms.has(r.service?.ref)) {
            r.service = mapNoms.get(r.service.ref);
          }
          if (r?.invoice?.pay_kind === partUUID) {
            r.rp = partValue;
          } else if (r?.invoice?.pay_kind === teminalUUID) {
            r.rp = r.amount;
          }
          if (!r.amount && !r.source_report && r.status !== 'Аннулирован') {
            if (r?.invoice?.pay_kind === partUUID) {
              r.amount = r.invoice.services.find((s) => r.service.ref === s.nomRef)?.amount || 0;
              r.rp = partValue;
            } else if (r?.invoice?.pay_kind === teminalUUID) {
              r.amount = r.invoice.services.find((s) => r.service.ref === s.nomRef)?.amount || 0;
            }
          }
          if (r?.invoice?.isSubContract && r.amount) {
            r.amount = r?.invoice?.ext_json?.subNomsPrice?.find(
                (row) => row.nomRef === r.service.ref)?.price || r.amount;
            r.partner_name = `${r.partner_name} (${r.invoice.ext_json?.subPartnerName || ''})`;
          }
          if (r.status === 'Аннулирован') r.rp = r.amount ? r.rp : 0;
          if (inDateRange(r.date)) {
            if (r.amount && r?.invoice?.pay_kind === partUUID) {
              const cash = r.amount - partValue;
              _totals[r.pay_kind] =
                (_totals[r.pay_kind] || 0) + partValue;
              _totals['Каса'] += cash;
            } else {
              _totals[r.pay_kind] = (_totals[r.pay_kind] || 0) + r.amount;
            }
          } else {
            _totals[r.pay_kind] = (_totals[r.pay_kind] || 0) + 0;
          }
          if (r.rv && lab['РВ']) _totals['РВ'] = (_totals['РВ'] || 0) + r.rv;
          delete r.partner;
          delete r.invoice;
        });
        setTotals(_totals);
        setData(data);
        setLoadingVisible(false);
        setShowDataGrid(true);
      });
  }, [easyPayOptions, filters, inDateRange, lab, payKindByRef]);

  useEffect(() => {
    if (start && end) applyFilters();
  }, [ start, end, applyFilters ]);

  const cellLabRender = useCallback(
    (cell) => (cell.data.number_doc ? (
        <Link to={`/lab_report/${cell.data.ref}`}>{cell.data.number_doc}</Link>
      ) : <>{cell.data.number_doc}</>)
  ,[]);

  const cellInvoiceRender = useCallback(
    (cell) => (cell.text ? (
        <Link to={`/order/${cell.data.invoiceRef}`}>{cell.text}</Link>
      ) : ( <>{cell.text}</> )), []
  );

  const changeReq = (e) => {
    branchOptions.mailcc = e.event?.target?.value ? e.event?.target?.value : undefined;
  };

  return (
    <PageContent size='large'>
      <LoadPanel visible={loadingVisible} />
      <div className='otk-page-header'>Звіт лабораторії за період</div>
      <Form stylingMode={FORM_STYLING_MODE}>
        <GroupItem colCount={4}>
          <SimpleItem label={labels.dateRange}>
            <DateRange
              startValue={filters.date.start}
              endValue={filters.date.end}
              stylingMode={FORM_STYLING_MODE}
              onRangeChanged={(range) => {
                if (!checkInterval(range.start, range.end)) showWarning('Період більше одного місяця');
                setFilters((prev) => ({ ...prev, date: range }));
              }}
              displayFormat={DX_DATE_DISPLAY_FORMAT}
              width='100%'
            />
          </SimpleItem>

          <ButtonItem
            buttonOptions={{
              disabled: !checkInterval(filters.date.start, filters.date.end),
              text: 'Cформувати',
              icon: 'search',
              type: 'default',
              stylingMode: 'outlined',
              onClick: () => navigate(`/daylabreport/${filters.date.start}/${filters.date.end}`),
            }}
            verticalAlignment='center'
            horizontalAlignment='center'
          />
          <EmptyItem />
          <SimpleItem colSpan={1}>
            <div>
              {Object.entries(totals).map((el) => {
                const [ key, value ] = el;
                return (
                  <div className={styles.cash} key={`${key}`}>
                    <span> {key} </span> <span>{value.toFixed(2)} </span>
                  </div>
                );
              })}
            </div>
          </SimpleItem>
        </GroupItem>
        <GroupItem cssClass={styles.dataGridItem} visible={showDataGrid}>
          {showDataGrid && (
            <DataGrid
              ref={dgRef}
              keyExpr='number_doc'
              className={styles.dataGrid}
              showBorders
              dataSource={data}
              focusedRowEnabled
              paging={{ enabled: false }}
              onRowPrepared={({ data, rowElement }) => {
                if (data && !inDateRange(data.date)) rowElement.classList.add('otk-row-status-warning-bg');
              }}
              columnAutoWidth>
              <Column
                dataField='vehicle_map'
                caption={labels.tableHeads.vehicle_map}
                dataType='boolean'
                alignment='center'
                width={40}
              />
              <Column
                dataField='number_doc'
                caption={labels.tableHeads.number_doc}
                dataType='string'
                alignment='center'
                cellRender={cellLabRender}
                width={120}
              />
              <Column
                dataField='invoiceNumberDoc'
                caption={labels.tableHeads.order}
                dataType='string'
                alignment='center'
                cellRender={cellInvoiceRender}
                width={140}
              />
              <Column
                dataField='blank'
                caption={labels.tableHeads.blank}
                dataType='string'
                alignment='center'
                width={90}
              />
              <Column
                dataField='status'
                caption={labels.tableHeads.status}
                dataType='string'
                alignment='center'
                width={120}
              />
              <Column
                dataField='date'
                caption={labels.tableHeads.date}
                dataType='date'
                alignment='center'
                width={90}
              />
              <Column
                dataField='time'
                caption={labels.tableHeads.time}
                alignment='center'
                width={80}
              />
              <Column
                name='TypeName'
                dataField='vehicle_model.type.name'
                caption={labels.tableHeads.vehicle_model.type.name}
                dataType='string'
                visible={false}
                width={120}
              />
              <Column
                name='BrandName'
                dataField='vehicle_model.brand.name'
                caption={labels.tableHeads.vehicle_model.brand.name}
                dataType='string'
                visible={false}
                width={120}
              />
              <Column
                name='ModelName'
                dataField='vehicle_model.name'
                caption={labels.tableHeads.vehicle_model.name}
                dataType='string'
                visible={false}
                width={120}
              />
              <Column
                dataField='service.name'
                caption={labels.tableHeads.service.name}
                dataType='string'
                // alignment='center'
                width={50}
              />
              <Column
                dataField='gnumber'
                caption={labels.tableHeads.gnumber}
                dataType='string'
                width={120}
              />
              <Column
                cssClass={styles.partnerColumn}
                dataField='partner_name'
                caption={labels.tableHeads.partner_name}
                dataType='string'
                alignment='left'
              />
              <Column
                dataField='individual_legal'
                caption={labels.tableHeads.individual_legal}
                dataType='string'
                width={50}
              />
              <Column
                dataField='pay_kind'
                caption={labels.tableHeads.pay_kind}
                dataType='string'
                cellRender={cellInvoiceRender}
                width={80}
              />
              <Column
                dataField='amount'
                caption={labels.tableHeads.amount}
                dataType='number'
                alignment='right'
              />
              <Column
                dataField='rp'
                caption={labels.tableHeads.rp}
                dataType='number'
                alignment='right'
              />
              <Column
                dataField='rv'
                caption={labels.tableHeads.rv}
                dataType='number'
                alignment='right'
                visible={lab['РВ']}
              />
              <Summary>
                <TotalItem
                  column='amount'
                  summaryType='sum'
                  valueFormat={(value) => value.toFixed(2)}
                  displayFormat='Всього: {0}'
                />
                <TotalItem
                  column='rp'
                  summaryType='sum'
                  valueFormat={(value) => value.toFixed(2)}
                  displayFormat='{0}'
                />
                {lab['РВ'] && <TotalItem
                  column='rv'
                  summaryType='sum'
                  valueFormat={(value) => value.toFixed(2)}
                  displayFormat='{0}'
                />}
              </Summary>
            </DataGrid>
          )}
        </GroupItem>
        <GroupItem colCount={1} visible={showDataGrid} >
          <div className={styles.pagefooter}>
            <div className="dx-field">
              <div className="dx-field-label">{labels.mailcc}</div>
                <TextBox width={250}
                  labelMode='static'
                  mode='email'
                  id='mailcc'
                  defaultValue={branchOptions.mailcc}
                  hint='копія отримувача звіту'
                  onChange={changeReq}
                  stylingMode={FORM_STYLING_MODE_UNDER}
                >
                  <Validator ref={validatorRef}><EmailRule message={'Некоректний email!'}/></Validator>
                </TextBox>
              </div>

             <Button className={styles.exportButton}
                text='Експорт'
                icon='exportxlsx'
                type='default'
                useSubmitBehavior={false}
                onClick={async () => {
                    const validation = validatorRef.current.instance.validate();
                    if (!validation.isValid) {
                      validation.brokenRules.forEach(rule => showError(rule.message));
                      return;
                    }
                    const filename = `daylabreport_${lab.lab_number}`;
                    const excelObjext = await exportExcelBuffer(filename, dgRef.current.instance);
                    updateBranchOptions(branchOptions);
                    gqlClient
                      .mutate({
                        mutation: gql`mutation sendLabDayReport($input: JSONObject) { sendLabDayReport(input: $input) }`,
                        variables: {
                          input: {
                            filename,
                            mailcc: branchOptions.mailcc,
                            buf: excelObjext.toString('base64'),
                          },
                        }
                      })
                      .then(({ sendLabDayReport }) => {
                        if (sendLabDayReport?._id === 'ok') showSuccess(notifySuccesEmailText);
                      })
                      .catch((err) => {
                        Sentry.captureException(err);
                        throw new Error('Помилка надсилання повіломлення');
                      });
                  }}
              />
          </div>
        </GroupItem>
      </Form>
    </PageContent>
  );
};
