import { Document } from '../_documets';
import dayjs from 'dayjs';
import { gqlClient } from 'gql-client.js';
import { gql } from 'graphql.macro';

const getOrderCollectionsQuery = gql`query getOrderCollections($ref: String) {
    order: getOrderCollections(ref: $ref) {
      _id
      ref
      doc_amount
      number_doc
      date
      proj
      note
      ext_json
      organization {ref name}
      department { ref }
      note
      orders { row ref caption amount partnerName},
    }
    payment: checkEasyPayPaymentInfo(ref: $ref) { body }
  }`;

const setOrderCollectionsQuery = gql`mutation setOrderCollections($input: JSONObject) {
    setOrderCollections(input: $input) { _id }
  }`;

export class OrderCollections extends Document {
  /**
    * Create a Document.
    * @param {string} ref ('new' as Deafault)
 */
  constructor(ref, { user, lab, options, projects }) {
    super({
      class_name: 'doc.order_collections',
      ref: ref ?? 'new',
      date: dayjs(),
    });
    this.department = lab.ref;
    this.doc_amount = 0;
    this.number_doc = '';
    this.proj = projects.otk;
    this.ext_json = {proj: 'otk'};
    this.note = '';
    this.organization = lab?.org ?? {};

    this.data = this._getEmptyDoc();
    this.orders = []
    this._isReadOnly = false;
    this._isClosed = false;

    this._options = options;
    this._user = user;
    this._lab = lab;
    this._paymentData = undefined;
  }

  get totalAmount() {
    return this.orders?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
  }

  _getEmptyDoc() {
    return {
      date: dayjs(),
      number_doc: '',
    };
  }

  renumberRows() {
    this.orders.forEach((r, i) => {
      r.row = i + 1;
    });
  }

  export() {
    const docToSave = super.export();
    return docToSave;
  }

  async save() {

    const docToSave = {
      ...this.data,
      ...this.export(),
    };
    docToSave.department = this.department?.ref ?? this.department;
    const doc_amount = this.totalAmount;
    docToSave.doc_amount = doc_amount;
    docToSave.ext_json = { ...this.data?.ext_json, ...this.ext_json };
    docToSave.note = this.note;
    docToSave.organization = this.organization?.ref;
    docToSave.proj = this.proj;
    docToSave.orders = this.orders;

    const response = await gqlClient.mutate({
      mutation: setOrderCollectionsQuery,
      variables: { input: docToSave },
    });

    if (response?.errors) {
      return Promise.reject(response.errors.map(r=>r?.message).join('\n'));
    }
    this.isNew = false;
    return Promise.resolve(this.ref);
  }

  async loadRaw(ref) {
    return gqlClient
      .query({ query: getOrderCollectionsQuery, variables: { ref: ref ?? this.ref } })
      .then((response) => {
        const loadOrder = response?.data?.order?.[0] || {};
        loadOrder.paymentData = response?.data?.payment?.[0]?.body;
        return loadOrder;
      });
  }

  updateDoc(data) {
    super.load(data);
    this._paymentData = data?.paymentData;
    this.department = data.department;
    this.doc_amount = data.doc_amount;
    this.proj = data.proj;
    this.ext_json = data.ext_json ?? {};
    this.note = data.note ?? '';
    this.data = data;
    this.isReadOnly = this.protected;
    this.orders = data.orders;
  }

  async load(ref) {
    return this.loadRaw(ref)
      .then((data) => {
        if (!data?.ref) return undefined;
        this.updateDoc(data);
        return data;
      });
  }
}
