import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/style.module.scss';

export const CardPaymentForm = ({ data }) => (
  <div className={styles?.iframe_container}>
    <iframe src={data.policy_payment?.forwardUrl} title='оплата'/>
  </div>
);

CardPaymentForm.propTypes = {
  data: PropTypes.object.isRequired,
};
export default CardPaymentForm;
