import { createDataSource } from 'utils/gql-datasource';
import { gql } from '@apollo/client';
import { splitId } from 'utils/helpers.js';

const BRANDS_QUERY = gql(
  `query dsCarBrands($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, 
    $sort: sort,
  $requireTotalCount: Boolean!
  ) {
    totalcount: car_brand(
        ref: $ref, jfilt: $jfilt, totalCount: 1
      ) @include(if: $requireTotalCount) 
  
    list: car_brand(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, 
      sort: $sort
    ) 
  }`);

const _dsCarBrands = createDataSource(BRANDS_QUERY, {
  processData: (data) => (
    data?.map((row) => ({ ...row, ref: splitId(row._id).ref }))
  ),
  rawmode: true,
});

export const dsCarBrands = _dsCarBrands;
