import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//components
import { FormField } from 'components/form-field/form-field';
import { Button, SelectBox, ValidationSummary } from 'devextreme-react';
import { CompaniesOffers } from './companies_offers/companies_offers';
import { AutoShortInfo } from './autoShortInfo';
import { checkVehicleTypeAB1_B3 } from './utils';

import styles from '../styles/style.module.scss';
import { getPeriodsList } from '../api/requests';

const franchiseList = [
  { title: '0', value: 0 },
  { title: '1500', value: 1500 },
  { title: '2500', value: 2500 },
];
export const Part2 = (props) => {
  const { setData = () => {}, data = {} } = props;
  const [ periodsList, setPeriodsList ] = useState([]);
  //блокуємо можливість вибру пільги(знижки)
  const blockPrivilege = data?.vehicle_engine_volume > 2500 ||
    !checkVehicleTypeAB1_B3(data?.vehicle_type);

  useEffect(() => {
    if (blockPrivilege) setData((prev) => ({ ...prev, privilege: 'without' }));
    getPeriodsList().then(data => setPeriodsList(data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    <h3>Крок 2. Вибір пропозицій від СК</h3>
    <AutoShortInfo data={data} />

    {false && //+++ in work, holded to answer
     <FormField textWidth={'300px'} text={'Строк дії договору: '}

       mrBottom={true}>
       <SelectBox
         id={'period'}
         items={periodsList}
         onValueChanged={(e) => {
           const value = e?.event?.target?.value || e?.value;
           setData((prev) => ({ ...prev, period: value }));
         }}
         value={data?.period}
         displayExpr="name"
         valueExpr="code"
         showClearButton={false}
         wrapItemText={true}
         stylingMode={'outlined'}
         width={'200px'}
         disabled={false}
       />
     </FormField>
    }
    <FormField textWidth={'300px'} text={'Пільги: '} mrBottom={true}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectBox
          items={data?.privileges || []}
          onSelectionChanged={(e) => {
            setData((prev) => ({ ...prev, privilege: e?.selectedItem?.code }));
          }}
          value={data?.privilege || null}
          displayExpr="name"
          valueExpr="code"
          wrapItemText={true}
          stylingMode={'outlined'}
          width={'200px'}
          disabled={blockPrivilege}
        >
        </SelectBox>
        {!blockPrivilege &&
        <Button icon='info'
          hint="Розмір страхового платежу за одним внутрішнім договором
          страхування зменшується на 50 відсотків, за умови, що страхувальником
          є громадянин України - учасник війни, особа з інвалідністю II групи,
          особа, яка постраждала внаслідок Чорнобильської катастрофи, віднесена
          до I або II категорії, пенсіонер, а забезпечений транспортний засіб
          має робочий об'єм двигуна до 2500 сантиметрів кубічних включно та
          належить цьому громадянину на праві власності. Зазначена пільга
          надається за умови особистого керування таким транспортним засобом
          особою, яка належить до визначених у цьому пункті категорій громадян
          України, без мети надання платних послуг з перевезення пасажирів або
          вантажу." />
        }
      </div>
    </FormField>

    <FormField textWidth={'300px'} text={'Франшиза: '}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectBox
          // id={'policy_type'}
          items={franchiseList}
          onSelectionChanged={(e) => {
            setData((prev) => ({ ...prev, franchise: e?.selectedItem?.value }));
          }}
          value={data?.franchise || 0}
          displayExpr="title"
          valueExpr="value"
          wrapItemText={true}
          stylingMode={'outlined'}
          width={'200px'}
        >
        </SelectBox>
        <Button icon='info'
          hint="Франшиза – це не оплачувана частина шкоди. Наприклад,
          франшиза – 1500 грн, сума збитків – 4500 грн.
          Компенсовано буде – 3000 грн."
        />
      </div>
    </FormField>
    {/* список пропозицій від СК */}
    <CompaniesOffers data={data} setData={setData}/>
    <ValidationSummary id="summary"/>
    <br />
    <FormField textWidth={'300px'} text={''}>
      <Button className={styles?.mr_40}
        onClick={() => setData((prev) => ({ ...prev, step: 1 }))}>
        {'< Назад'}
      </Button>
      {/* <Button onClick={handleSearch}>{'Далі >'}</Button> */}
    </FormField>
    <br />
  </>);
};
Part2.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
};

export default Part2;
