import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import PropTypes from 'prop-types';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { ManualButton, NotificationBadge, VersionCheck } from 'components';
import { useDocChangeListener, useMessageVars } from 'hooks';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const getMessages = loader('../../contexts/messages/getMessages.gql');

export const Header = ({ menuToggleEnabled, title, toggleMenu }) => {
  const [ isEvents, setEvents ] = useState(false);

  const counts = useMessageVars([ 'reportsWithErrorCount', 'newOrderCount' ]);
  useDocChangeListener('shed.appoint', () => setEvents(true));

  useQuery(getMessages, {
    skip: false,
    variables: { jfilt: [ { fld: 'class_name', expr: '=', val: 'shed.appoint' } ] },
    onCompleted: (data) => setEvents(data?.messages?.[0]?.mes_array.length > 0),
   });

  const totalNotfications = counts.reduce((a, b) => a + (b || 0), 0);
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item location={'before'} visible={menuToggleEnabled} widget={'dxButton'} cssClass={'menu-button'}>
          <NotificationBadge
            count={totalNotfications}
            containerClassName='menu-button-container'
            badgeClassName='menu-button-badge'
            >
            <Button icon='menu' stylingMode='text' onClick={toggleMenu}/>
          </NotificationBadge>
        </Item>

        <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title}/>
        <Item location={'after'}><VersionCheck/></Item>

        <Item location={'after'}>
          <div className='header-phone'>
            <div className='dx-icon-tel header-phone-icon'/>
            <b className='header-phone-number'>(067) 820-39-66</b>
          </div>
        </Item>

        <Item location={'after'}><ManualButton /></Item>
        <Item location={'after'}>
          <NotificationBadge
            containerClassName='menu-button-container'
            badgeClassName='menu-button-badge'
            dot = {isEvents}
            visible={isEvents}
            count={1}
          >
          </NotificationBadge>
          <Link to={'/sheduler'}><Button icon='event' onClick={() => setEvents(false)}/></Link>
        </Item>

        <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
          <Button
            className={'user-button authorization'}
            width={140}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>

        <Template name={'userPanelTemplate'}><UserPanel menuMode={'list'} /></Template>
      </Toolbar>
    </header>
  );
};

Header.propTypes = {
  menuToggleEnabled: PropTypes.bool,
  toggleMenu: PropTypes.func,
  title: PropTypes.string,
};
