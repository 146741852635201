import { gql } from 'graphql.macro';
import { calculateStatus } from 'utils/calculate-order-status.js';
import { createDataSource } from 'utils/gql-datasource.js';

const dsGetOrders = gql`query dsGetOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, 
  $sort: sort, $requireTotalCount: Boolean!,$userData:JSON
) {
  totalcount: buyers_orders(
    ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData
  ) @include(if: $requireTotalCount) {
    totalcount
  }

  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, 
    sort: $sort, userData: $userData
  ) {
    ref 
    number_doc 
    date 
    doc_amount 
    caption 
    protected 
    shipped
    paid
    proj
    pay_kind
    note 
    partner {
      ref 
      name
    }
    isSubContract
    ext_json
  }
}`;//loader('./dsGetOrders.gql');

export const dsOrders = createDataSource(dsGetOrders, {
  processData: (data) => (
    data?.map((row) => ({ ...row, status: calculateStatus(row) }))
  ),
});

dsOrders.on('loading', (options) => {
  options.userData = dsOrders?.userData;
});

