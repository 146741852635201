import { showError } from 'utils/notify.js';

//документація https://docs.google.com/document/d/1pEBQ8lfD0g8QsX6Zi37duX3GGk21H_T45X1PMJ81FcU/edit
//інпортуємо для побудови базового URL запитів
const proxyUrl = process.env.REACT_APP_POLICY_API_PROXY_API;

export class API_PAYMENT {
  constructor(entity = '') {
    this.URL = `${proxyUrl}/easypay/${entity}`;
    this.pkey = '';
  }

  async errorShowMes(data = {}) {
    const dataJson = await data?.json() || {};
    showError(dataJson?.error?.errorMessage || 'Error', 5000);
  }

  async request({ url, method, isAuth, body, type }, opt) {
    const headers = { ...opt };
    const options = { method };
    //token use soon
    const token = '';
    if (isAuth) headers.authorization = `Bearer ${token}`;
    //==============
    if (type !== 'file' && body) {
      options.body = JSON.stringify(body);
      headers['content-type'] = 'application/json';
    }

    headers.pkey = this.pkey;
    options.headers = headers;
    return fetch(url, options).then((res) => {
      if (res?.status === 200) {
        return res.json();
      }
      this.errorShowMes(res);
      return null;

    }).catch((error) => {
      console.log('error', error);
    });
  }

  post(path = '', isAuth = false, body = {}, opt = {}) {
    return this.request({
      url: `${this.URL}/${path}`,
      method: 'POST',
      isAuth,
      body,
    }, opt);
  }

}
