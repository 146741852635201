import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import { Menu, Tooltip } from 'devextreme-react';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { API_HOST, COLUMN_PRICE_WIDTH } from 'app-constants';
import { openDoc } from 'utils/open-doc';
import { useDocChangeListener, useProj, useMessageVars, usePayKinds, useMessageHandler } from 'hooks';
import { DoclistPageTemplate, statusCellRender } from 'components';
import { dsProj, dsOrders } from 'datasources';
import styles from './order.module.scss';
import { OptionsBar } from './compnents/ordersOprionsBar/optionsBar';


const printMenuItems = ({ ref, number_doc }) => ({
  text: 'Друк',
  icon: 'print',
  disabled: !(ref && number_doc),
  items: [
    {
      id: 'print',
      text: 'Рахунок',
      url: `${API_HOST}/printform/${ref}/inv`,
    },
    {
      id: 'print',
      text: 'Договір',
      url: `${API_HOST}/printform/${ref}/dog`,
    },
    {
      id: 'print',
      text: 'Договір сертифікації',
      url: `${API_HOST}/printform/${ref}/dogs`,
    },
    {
      id: 'print',
      text: 'Договір для Казначейства',
      url: `${API_HOST}/printform/${ref}/dogk`,
    },
  ],
});

const easyPayMenuItems = {
  text: 'EasyPay',
  icon: 'img/easypay.png',
  id: 'easypay',
  items: [
    // {
    //   text: '(не підтримується) Надіслати в термінал',
    //   icon: 'export',
    //   id: 'easypay-export',
    //   disabled: true,
    // },
    // {
    //   text: 'Актуальний код',
    //   icon: 'find',
    //   id: 'easypayLastCode',
    // },
    {
      beginGroup: true,
      text: 'Повідомлення про інциденти',
      icon: 'message',
      id: 'easypayConflicts',
    },
    {
      beginGroup: true,
      text: 'Список в терміналі',
      icon: 'pinright',
      id: 'termlist',
    },
    {
      beginGroup: true,
      text: 'Групові оплати (он-онлайн)',
      icon: 'ion ion-md-card',
      id: 'easypayGroupPay',
      // disabled: process.env.NODE_ENV !== 'development'
    },
  ],
};

const reportMenuItems = {
  text: 'Звіти',
  icon: 'verticalalignbottom',
  id: 'reports',
  items: [
    {
      id: 'reports',
      icon: 'money',
      text: 'Рух коштів',
      url: '/cash_flow_report',
    },
    {
      id: 'reports',
      icon: 'money',
      text: 'Заборгованість за виконаними роботами',
      url: '/debitsbyact_report',
    },
  ],
};

export const Orders = () => {
  const [ selectedRowData, setSelectedRowData ] = useState({});
  const navigate = useNavigate();
  const gridRef = useRef();
  const legendProtectedRef = useRef();
  const legendIsSubContractRef = useRef();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const showNewOrders = !!params.get('show_new');

  const { proj } = useProj();
  const [ userData, setUserData ] = useState({});
  const { payKinds } = usePayKinds();
  const [ newOrders ] = useMessageVars([ 'newOrdersList' ]);
  const msgHandler = useMessageHandler();

  useDocChangeListener('doc.buyers_order', () => {
    gridRef.current.instance.refresh(true);
  });

  const cellShippedRender = useCallback((cell) => {
    const { shipped, pay_kind } = cell.data;
    return ((shipped && pay_kind === '8cb8e96e-63da-11ec-bb44-00155d000c06') ?
      <Link to={`/acts?trans_ref=${cell.data.ref}`}>{cell.data.shipped}</Link> :
      <>{cell.data.shipped}</>);
  }, [],

  );

  // const easyPayReport = () => {
  //   gqlClient
  //     .query({
  //       query: easyPayReportCreate,
  //       // variables: {},
  //     })
  //     .then((response) => {
  //       let report = response?.data?.easyPayReportCreate?.retValue?.[0];
  //       let myDialog;
  //       if (report) {
  //         report = report.report;
  //         myDialog = custom({
  //           title: `Надіслано ${report.orders.length} документ(ів)`,
  //           messageHtml:
  //           `<p style="text-align:center;">
  //           Звіт надіслано.<br/>
  //           Код для терміналу:<br/>
  //           <b><font size="+10">${report.code}</font size></b><br/>
  //           Буде доступний в терміналі через 15-20хв.`,
  //           buttons: [
  //             {
  //               text: texts.continue,
  //               onClick: () => 1,
  //             },
  //           ],
  //         });
  //       } else {
  //         myDialog = custom({
  //           title: 'Пусто',
  //           messageHtml:
  //           `<p style="text-align:center;">
  //           Немає замовлень відповідного типу для надсилання.`,
  //           buttons: [
  //             {
  //               text: texts.continue,
  //               onClick: () => 1,
  //             },
  //           ],
  //         });

  //       }
  //       myDialog.show().then(() => {});
  //     });
  // };

  // const easyPayLastCode = () => {
  //   gqlClient
  //     .query({
  //       query: easyPayLastCodeGql,
  //       // variables: {},
  //     })
  //     .then((response) => {
  //       const report = response?.data?.easyPayLastCode || { code: '' };
  //       let myDialog;
  //       if (report?.code) {
  //         myDialog = custom({
  //           title: `Дата: 
  //             ${dayjs(report.date).format(DATE_DISPLAY_FORMAT)} `,
  //           messageHtml:
  //           `<p style="text-align:center;">
  //           Актуальний код для терміналу:<br/>
  //           <b><font size="+10">${report.code}</font size></b><br/>
  //           `,
  //           buttons: [
  //             {
  //               text: texts.continue,
  //               onClick: () => 1,
  //             },
  //           ],
  //         });
  //       } else {
  //         myDialog = custom({
  //           title: 'Пусто',
  //           messageHtml:
  //           `<p style="text-align:center;">
  //           Документи в термінал оплати ще не надсилались.`,
  //           buttons: [
  //             {
  //               text: texts.continue,
  //               onClick: () => 1,
  //             },
  //           ],
  //         });

  //       }
  //       myDialog.show().then(() => {});
  //     });
  // };

  useEffect(() => {
    dsOrders.userData = userData;
    gridRef.current.instance.refresh();
  }, [ userData ]);

  useEffect(() => function cleanup() {
    dsOrders.userData = undefined;
  });

  return (<>
    <DoclistPageTemplate
      ref={gridRef}
      deps={[ proj, newOrders, payKinds, userData ]}
      pageName='Замовлення'
      onEdit={(ref) => {
        const msg = newOrders.find((msg) => msg.ref === ref);
        if (msg) msgHandler.removeById([ msg.id ]);
        navigate(`/order/${ref}`);
      }}
      dataSource={dsOrders}
      onRowPrepared={(e) => {
        if (e.data?.protected && e.rowType === 'data') {
          e.rowElement.classList.add('otk-row-status-warning');
        }
      }}
      onCellPrepared={(e) => {
        if (
          e.column.dataField === 'number_doc' &&
          e.rowType === 'data' &&
          newOrders.some((msg) => msg.ref === e.data?.ref)
        ) {
          e.cellElement.classList.add(styles.cellNewOrder);
        }
        if (
          e.column.dataField === 'proj' &&
          e.rowType === 'data' && e.data?.isSubContract) {
          e.cellElement.classList.add('otk-row-cell-issubcontract');
        }
      }}
      onSelectionChanged={(e) => setSelectedRowData(e.selectedRowsData[0] || {})}
      onCellHoverChanged = {(e)=>{
        let {column,data,eventType, cellElement} = e;
        if (eventType==='mouseover') {
          if (column?.name === 'buttons' && !!data?.protected) legendProtectedRef.current.instance.show(cellElement);
          if (column?.name === 'proj' && data.isSubContract) legendIsSubContractRef.current.instance.show(cellElement);
        }
      }}
      stateStoringEnabled={!showNewOrders}
      menuComponent={(
        <Menu
          onItemClick={(e) => {
            switch (e.itemData.id) {
            case 'new': {
              navigate('/order/new');
              break;
            }
            case 'print': {
              openDoc(e.itemData.url);
              break;
            }
            // case 'easypay-export': {
            //   easyPayReport();
            //   break;
            // }
            // case 'easypayLastCode': {
            //   easyPayLastCode();
            //   break;
            // }
            case 'easypayConflicts': {
              navigate('/easypays');
              break;
            }
            case 'termlist': {
              navigate('/termlist');
              break;
            }
            case 'easypayGroupPay': {
              navigate('/orderCollections');
              break;
            }
            case 'reports': {
              if (e.itemData.url) navigate(e.itemData.url);
              break;
            }
            default:
            }
          }}
          dataSource={[
            {
              text: 'Додати',
              icon: 'add',
              id: 'new',
            },
            printMenuItems(selectedRowData),
            easyPayMenuItems,
            reportMenuItems,
          ]}
        />
      )}
      optionsComponent={(<OptionsBar
        onChange={(gos_code) => {setUserData((prev) => ({...prev, services: gos_code ? [ { gos_code } ] : undefined }))}}
        onChangeEdrpou = {(edrpou) => setUserData((prev) => ({...prev, edrpou: edrpou ?? undefined }))}
      />)}
    >
      <Column
        dataField='proj'
        dataType='string'
        caption='Тип'
        alignment='left'
        allowHiding={false}
        allowResizing={true}
        width={120}
        minWidth={60}
      >
        <Lookup dataSource={dsProj} valueExpr='ref' displayExpr='name' allowClearing={true}/>
      </Column>

      <Column
        allowSorting={false}
        allowResizing={true}
        dataField='pay_kind'
        caption='Тип опл'
        maxWidth={130}
        minWidth={90}
      >
        <Lookup dataSource={payKinds} valueExpr='ref' displayExpr='name'/>
      </Column>

      <Column 
        allowSorting={true}
        allowResizing={true}
        allowHiding={false}
        dataField='doc_amount'
        caption='Сума'
        dataType='number'
        alignment='right'
        width={COLUMN_PRICE_WIDTH}
        minWidth={90}
      />

      <Column
        allowSorting={false}
        dataField='shipped'
        caption='Відвантажено'
        dataType='number'
        alignment='right'
        cellRender={cellShippedRender}
        width={100}
      />

      <Column
        allowSorting={false}
        dataField='paid'
        caption='Сплачено'
        dataType='number'
        alignment='right'
        hidingPriority={2}
        width={100}
      />
      <Column
        allowSorting={false}
        dataField='ext_json.payDateEasyPay'
        caption='T'
        dataType='boolean'
        alignment='center'
        calculateCellValue={(data) => !!data?.ext_json?.payDateEasyPay}
        hidingPriority={1}
      />
      <Column
        cssClass='otk-cell-no-padding'
        allowSearch={false}
        allowFiltering={false}
        dataField='status'
        dataType='string'
        caption='Статус'
        alignment='center'
        hidingPriority={1}
        width={130}
        cellRender={(data) => statusCellRender(data, null, (s) => s.status)}
      />
          
    </DoclistPageTemplate>
    <Tooltip ref={legendProtectedRef} contentComponent={protectLegend} hideEvent='mouseleave'/>      
    <Tooltip ref={legendIsSubContractRef} contentComponent={isSubContractLegend} hideEvent='mouseleave' />      
        
    </>

  );
};

const protectLegend =()=>{
  return (<div style={{display:'flex', background:'#fffbcf'}}>
     <span className='otk-row-status-warning' style={{padding:'10px 10px 10px 20px'}}>Документ захищений</span>
     </div>)
}

const isSubContractLegend =()=>{
  return (<div style={{display:'flex', background:'#fffbcf'}}>
    <span className='otk-row-cell-issubcontract' style={{padding:'10px 10px 10px 20px'}}>Генпідрядний</span>
    </div>)
}
