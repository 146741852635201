import { gql } from 'graphql.macro';
// import { calculateStatus } from 'utils/calculate-order-status.js';
import { createDataSource } from 'utils/gql-datasource.js';

const queryGetOrderCollections = gql`query getOrderCollections($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, 
    $sort: sort, $requireTotalCount: Boolean!,$userData:JSON
  ) {
    totalcount: getOrderCollections(
      ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData
    ) @include(if: $requireTotalCount) {
      totalcount
    }
  
    list: getOrderCollections(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, 
      sort: $sort, userData: $userData
    ) {
      ref 
      number_doc 
      date 
      doc_amount 
      caption 
      ext_json
    #   protected 
    #   shipped
    #   paid
    #   proj
    #   pay_kind
    #   note 
    #    partner {
    #     ref 
        #  name
    #    }
    #   isSubContract
    }
  }`;

export const dsOrderCollections = createDataSource(queryGetOrderCollections, {
  processData: (data) => (
    //data
    data?.map((row) => ({ ...row, pay: !!row?.ext_json?.paymentId }))
  ),
});

dsOrderCollections.on('loading', (options) => {
  options.userData = dsOrderCollections?.userData;
});