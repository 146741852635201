import { gql } from '@apollo/client';
import { gqlClient } from 'gql-client';
import CustomStore from 'devextreme/data/custom_store';

const gQuery = gql(
  `query getOrgs($ref: String, $limit: Int, $offset: Int, $jfilt: [JSON]) {
      totalcount: getOrgs(ref: $ref, totalCount: 1) {totalcount}
      getOrgs(ref: $ref, limit: $limit, offset: $offset, jfilt: $jfilt) { ref name }
  }`);
let orgs = [];

const loadOrgs = async (options) => {
  if (!orgs.length) {
    const resp = await gqlClient.query({ query: gQuery });
    orgs = resp?.data?.getOrgs || [];
  }
  const { searchExpr, searchValue } = options || {};
  if (searchValue) {
    return {
      data: orgs.filter((r) => r[searchExpr].includes(searchValue)) };
  }
  return { data: orgs };
};

const getByKey = async (key) => {
  if (!orgs.length) await loadOrgs();
  return orgs.find((r) => r.ref === key) || {};
};

export const dsOrgs = new CustomStore({
  key: 'ref',
  byKey: getByKey,
  load: loadOrgs,
});
