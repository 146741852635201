export const cities = [
    { id: '4625110100', name: 'Інші' },
    { id: '8000000000', name: 'м.Київ' },
    { id: '1210100000', name: 'Дніпро' },
    { id: '4610100000', name: 'Львів' },
    { id: '5110100000', name: 'Одеса' },
    { id: '6310100000', name: 'Харків' },
    { id: '3210500000', name: 'Бориспіль' },
    { id: '3222410300', name: 'Боярка' },
    { id: '3210600000', name: 'Бровари' },
    { id: '3210800000', name: 'Буча' },
    { id: '3210700000', name: 'Васильків' },
    { id: '3221810100', name: 'Вишгород' },
    { id: '3210900000', name: 'Ірпінь' },
    { id: '3211600000', name: 'Обухів' },
    { id: '1410100000', name: 'Донецьк' },
    { id: '2310100000', name: 'Запоріжжя' },
    { id: '1211000000', name: 'Кривий Ріг' },
    { id: '4411200000', name: 'Алчевськ' },
    { id: '2310400000', name: 'Бердянськ' },
    { id: '3210300000', name: 'Біла Церква' },
    { id: '0510100000', name: 'Вінниця' },
    { id: '1410600000', name: 'Горлівка' },
    { id: '0110900000', name: 'Євпаторія' },
    { id: '1810100000', name: 'Житомир' },
    { id: '2610100000', name: 'Івано-Франківськ' },
    { id: '6810400000', name: 'Кам\'янець-Подільський' },
    { id: '1210400000', name: 'Кам\'янське' },
    { id: '0111200000', name: 'Керч' },
    { id: '3510100000', name: 'Кропивницький' },
    { id: '1412900000', name: 'Краматорськ' },
    { id: '5310400000', name: 'Кременчук' },
    { id: '4411800000', name: 'Лисичанськ' },
    { id: '4410100000', name: 'Луганськ' },
    { id: '0710100000', name: 'Луцьк' },
    { id: '1413500000', name: 'Макіївка' },
    { id: '1412300000', name: 'Маріуполь' },
    { id: '2310700000', name: 'Мелітополь' },
    { id: '4623010100', name: 'Миколаїв' },
    { id: '1211600000', name: 'Нікополь' },
    { id: '1212400000', name: 'Павлоград' },
    { id: '5310100000', name: 'Полтава' },
    { id: '5610100000', name: 'Рівне' },
    { id: '8500000000', name: 'Севастополь' },
    { id: '4412900000', name: 'Сєвєродонецьк' },
    { id: '0110100000', name: 'Сімферополь' },
    { id: '1414100000', name: 'Слов\'янськ' },
    { id: '5910100000', name: 'Суми' },
    { id: '6110100000', name: 'Тернопіль' },
    { id: '2110100000', name: 'Ужгород' },
    { id: '6510100000', name: 'Херсон' },
    { id: '6810100000', name: 'Хмельницький' },
    { id: '7110100000', name: 'Черкаси' },
    { id: '7310100000', name: 'Чернівці' },
    { id: '7410100000', name: 'Чернігів' },
  ];
