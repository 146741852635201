import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//components
import { FormField } from 'components/form-field/form-field';
import {
  TextBox,
  Button,
  List,
} from 'devextreme-react';
import Validator, { RequiredRule } from 'devextreme-react/validator';

import { getVehicleCitiesSearch } from '../api/requests';

import styles from '../styles/style.module.scss';


const required = 'обов`язкове поле';

export const SearchCity = (props) => {
  const { setData = () => {}, data = {} } = props;
  const [ loading, setLoading ] = useState(false);
  const [ showList, setShowList ] = useState(false);

  const [ vehicleCitysList, setVehicleCitysList ] = useState([]);
  const [ status, setStatus ] = useState(loading ? 'pending' :
    data?.vehicle_city_id ? 'valid' : 'invalid');
  const handleSearch = async () => {
    setLoading(true);
    if (data?.vehicle_city?.length > 1) {
      const search = await getVehicleCitiesSearch(data?.vehicle_city);
      if (search?.data) {
        setVehicleCitysList(search?.data);
        setShowList(true);
      }
    }
    setLoading(false);
  };
  const handleOptionChanged = (e) => {
    const value = e?.event?.target?.value || e?.value;
    if (e?.name === 'text' && e?.previousValue !== undefined) {
      setData((prev) => ({
        ...prev,
        vehicle_city: value,
        vehicle_city_id: null }));
    }
  };
  const selectCityItem = (e) => {
    if (e?.itemData) {
      setData((prev) => ({
        ...prev,
        vehicle_city: e?.itemData?.fullname,
        vehicle_city_id: e?.itemData?.id,
      }));
      setShowList(false);
    }
  };
  useEffect(() => {
    setStatus(loading ? 'pending' :
      data?.vehicle_city_id ? 'valid' : 'invalid');
  }, [ loading, data?.vehicle_city_id ]);
  return (<>
    <>
      <FormField textWidth={'300px'} text={'Місто реєстрації власника ТЗ: '}
        className={styles?.flex_wrap}>
        <div className={styles?.custome_search_wrap}>
          <div>{!data?.vehicle_city_id &&
            <span className={styles?.custome_search_text}>
              {'(значення має бути вибране зі списку результатів пошуку)'}
            </span>
          }</div>
          <Button
            className={styles?.custome_search_button}
            type={'normal'}
            onClick={handleSearch}
            stylingMode={'outlined'}
            height={32}
            width={60}
            disabled={loading}
          >
            Пошук
          </Button>
          <TextBox
            id={'vehicle_city'}
            value={data?.vehicle_city}
            stylingMode={'outlined'}
            onOptionChanged={handleOptionChanged}
            onKeyUp={(e) => {
              if (e?.event?.code === 'Enter') handleSearch();
            }}
            width={'360px'}
            validationStatus={status}
          >
            <Validator>
              <RequiredRule
                message=
                  {`Місто реєстрації власника ТЗ - ${required}`}
              />
            </Validator>
          </TextBox>
          {showList && <List
            className={styles?.custome_search_list}
            dataSource={vehicleCitysList}
            displayExpr={'fullname'}
            onItemClick={selectCityItem}
            width={'420px'}
          />}
        </div>
      </FormField>
      <br />
    </>
  </>);
};

SearchCity.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
};

export default SearchCity;
