import { useCallback, useEffect, useState } from 'react';
import { getPeriodsList, getVehicleTypesList } from './api/requests';

export const useVehicleTypesList = () => {
    const [ vehicleTypesList, setVehicleTypesList ] = useState([]);
    const [ periodsList, setPeriods ] = useState([]);

    const callbackMemoized = useCallback(() => {
        getVehicleTypesList().then(setVehicleTypesList);
        getPeriodsList().then(setPeriods);
    }, [ ]);

    useEffect(() => {
        callbackMemoized();
    }, [ callbackMemoized ]);

    return {
        vehicleTypesList, periodsList
    };
};
