import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getOrdersForSelect } from './queries.js';
import { Column, Lookup, Selection } from 'devextreme-react/data-grid.js';
import { usePayKinds } from 'hooks/index.js';
import { showError } from 'utils/notify.js';

const { Popup, DataGrid, Button, CheckBox, SelectBox, LoadPanel } = require('devextreme-react');

export const OrderSelector = forwardRef(({ ordersList, proj, onSelected }, ref) => {
  const [orders, setOrders] = useState([]);
  const [visible, setVisible] = useState(false);
  const [orgFilt, setOrgFilt] = useState();
  const [labFilt, setLabFilt] = useState();
  const [total, setTotal] = useState(0);
  const [withTerminal, setWithTerminal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const {options} = useAuth();

  const { payKinds } = usePayKinds();
  const filtersRef = useRef({ orgs: [], labs: [] });
  const popupRef = useRef();
  const gridRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      show,
    };
  });

  const show = () => {
    setVisible(true);
  };

  const filteredOrders = useCallback(() => {
    const orgFiltArray = orders.filter((r) => !orgFilt || r.organization.ref === orgFilt);
    const labFiltArray = orgFiltArray.filter((r) => !labFilt || r.department.ref === labFilt);
    return labFiltArray;
  }, [orders, orgFilt, labFilt]);

  useEffect(() => {
    // const { partUUID, partValue} = options.easyPay; //T300 pay_kind & value
    if (visible){
      setLoading(true);
      getOrdersForSelect(
        ordersList.map((r) => r.ref),
        proj,
        withTerminal
      ).then((data) => {
        const handleData = data?.map((doc) => {
          // +++ ///
          // if (doc.pay_kind === partUUID) { //check for T300
          //   doc.doc_amount = doc.services.reduce((prev, curr)=>(prev+curr.quantity*partValue),0);
          // }
          return doc;
        });
        setOrders(handleData);
        filtersRef.current.orgs = handleData.reduce((prev, curr) => {
          if (!prev.find((r) => r.ref === curr.organization.ref)) prev.push(curr.organization);
          return prev;
        }, []);
        filtersRef.current.labs = handleData.reduce((prev, curr) => {
          if (!prev.find((r) => r.ref === curr.department.ref)) prev.push(curr.department);
          return prev;
        }, []);
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [ordersList, withTerminal, proj, visible]);

  const renderTitle = () => {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', padding: '16px 8px 16px 0px', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', gap: '5px' }}>
          <Button
            text='Вийти'
            hint='зміни не будуть перенесені в документ'
            type='danger'
            onClick={() => popupRef.current.instance.hide()}
          />
          <Button
            text='Вибрати'
            type='success'
            onClick={() => {
              const rows = gridRef.current?.instance?.getSelectedRowsData();
              //validate selection
              const orgsArray = rows.reduce((prev,curr)=>{
                return prev.includes(curr.organization.ref) ? prev : [ ...prev, curr.organization.ref ];
              }, []);
              const labsArray = rows.reduce((prev,curr)=>{
                return prev.includes(curr.department.ref) ? prev: [ ...prev, curr.department.ref ];
              }, []);
              if (orgsArray.length>1 || labsArray.length>1){
                showError('Замовлення з різних організацій чи лабораторій');
                return;
              }
              // end validation
              onSelected(rows, rows?.[0]?.organization.ref, rows?.[0]?.department.ref, );
              popupRef.current.instance.hide();
            }}
          />
        </div>
        <div>
          <CheckBox
            value={withTerminal}
            onValueChanged={({ value }) => setWithTerminal(value)}
            text='Термінальні (без Т300)'
          />
        </div>
        <div>
          <span>
            Вибрано на: <span style={{ fontWeight: '500', fontSize: '16px' }}>{total}</span> грн.
          </span>
        </div>
      </div>
    );
  };
  return (
    <Popup
      ref={popupRef}
      visible={visible}
      titleRender={renderTitle}
      closeOnOutsideClick
      width={'700px'}
      onShown={()=>gridRef.current?.instance?.selectRows(ordersList.map((r) => r.ref))}
      onHiding={() => setVisible(false)}
    >
      <div style={{ height: '100%' }}>
        <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
          <SelectBox
            showClearButton
            width={'100%'}
            label='організація'
            valueExpr='ref'
            displayExpr='name'
            items={filtersRef.current.orgs}
            value={orgFilt}
            onValueChanged={({ value }) => {
              setOrgFilt(value);
              setLabFilt();
            }}
          />
          <SelectBox
            showClearButton
            width={'100%'}
            label='лабораторія'
            valueExpr='ref'
            displayExpr='name'
            items={
              orgFilt ? filtersRef.current.labs.filter((r) => r.organization === orgFilt) : filtersRef.current.labs
            }
            value={labFilt}
            onValueChanged={({ value }) => setLabFilt(value)}
          />
        </div>
        <DataGrid
          ref={gridRef}
          keyExpr={'ref'}
          dataSource={filteredOrders()}
          paging={{ enabled: false }}
          height={'100%'}
          onSelectionChanged={({ selectedRowsData }) =>
            setTotal(selectedRowsData.reduce((prev, curr) => prev + curr.doc_amount, 0))
          }
        
        >
          <Selection mode='multiple' showCheckBoxesMode={'always'} />
          <Column dataField={'number_doc'} caption='Номер' />
          <Column dataField={'date'} caption='Дата' format='dd.MM.yyyy hh:mm' dataType={'date'} />
          <Column dataField={'pay_kind'} caption='Опл'>
            <Lookup dataSource={payKinds} valueExpr='ref' displayExpr='name' />
          </Column>
          <Column dataField={'doc_amount'} caption='Сума' />
        </DataGrid>
        <LoadPanel visible={loading}  />
      </div>
    </Popup>
  );
});
