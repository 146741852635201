import { LOGIN_TIMESTAMP_KEY } from 'app-constants';
import { useEffect, useMemo } from 'react';
import safeLocalStorage from 'safe-localstorage';

const LS_KEY_PREFIX = 'last_view_';

const getValue = (key) => safeLocalStorage.get(LS_KEY_PREFIX + key);
const updateValue = (key, value) => safeLocalStorage.set(LS_KEY_PREFIX + key, value);

export const useFirstLoginedView = (key, minStay = 0) => {
  const lastView = useMemo(() => getValue(key), [key]);
  const lastLogin = useMemo(() => safeLocalStorage.get(LOGIN_TIMESTAMP_KEY),[]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastLogin !== lastView) updateValue(key, lastLogin);
    }, minStay);
    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lastLogin !== lastView;
};

export const setLoginTimeStamp = () => {
  safeLocalStorage.set(LOGIN_TIMESTAMP_KEY, Date.now());
};
