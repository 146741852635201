const { gqlClient } = require("gql-client.js");
const { gql } = require("graphql.macro");
const { messages } = require("messages.js");
const { useState, useEffect } = require("react");
const { showError } = require("utils/notify.js");

const getBrands = gql`query ($limit: Int) { car_brand(limit: $limit) }`;

export const useBrands = () => {
    const [brands, setBrands] = useState([]);
    const [brandList, setBrandList] = useState([]);
  useEffect(() => {
    gqlClient
      .query({ query: getBrands, variables: {}, })
      .then((response) => {
        const res = response?.data?.car_brand;
        if (res) {
            setBrands(res);
            setBrandList(res?.map((item) => item?.name));
        }
      })
      .catch(() => showError(messages?.DATA_LOAD_FAILED));
  }, []);

  return {
    brands, brandList
  };
};