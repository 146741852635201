import { loader } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';
const dsGetNews = loader('./dsGetNews.gql');

export const dsNews = createDataSource(dsGetNews, {
  processData: (data) => (
    data?.map((row) => ({ ...row }))
  ),
});


