import React, { useState } from 'react';
import { Popup, NumberBox } from 'devextreme-react';
import PropTypes from 'prop-types';

export const NatsPopup = ({ changeNats, onHide, showDiscont, defNats = 0, defDiscont = 0 }) => {

  const [ valN, setValN ] = useState(defNats);
  const [ valD, setValD ] = useState(defDiscont);
  const onChange = (val, discont = false) => {
    if (discont) {
      setValD(val);
    } else {
      setValN(val);
    }
    changeNats(val, discont);
  };

  return (
    <Popup
      visible={true}
      onHiding={() => onHide(false)}
      hideOnOutsideClick={true}
      closeOnOutsideClick={true}
      showCloseButton={true}
      width={400}
      height={120}
      showTitle={true}
      title='Процент націнки'
      container='.dx-viewport'>
      <div style={{ display: 'flex' }}>
        <NumberBox
          format='#0.0%'
          label='Націнка'
          min={0}
          max={0.5}
          showClearButton
          showSpinButtons={true}
          onInput={(val) => onChange(val.component._parsedValue)}
          onValueChange={(val) => onChange(val)}
          defaultValue={0.0}
          step={0.01}
          value={valN}
        />
        {showDiscont &&
        <NumberBox
          style={{ marginLeft: '15px' }}
          format='#0.0%'
          label='Скидка'
          min={0}
          max={0.5}
          visible={showDiscont}
          showClearButton
          showSpinButtons={true}
          onInput={(val) => {
            onChange(val.component._parsedValue, true);
          }}
          onValueChange={(val) => {
            onChange(val, true);
          }}
          defaultValue={0.0}
          step={0.01}
          value={valD}
        />}
      </div>
    </Popup>
  );
};

NatsPopup.propTypes = {
  changeNats: PropTypes.func,
  onHide: PropTypes.func,
  showDiscont: PropTypes.bool,
  defNats: PropTypes.number,
  defDiscont: PropTypes.number,
};
