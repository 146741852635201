import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Popup, Form } from 'devextreme-react';
import { SimpleItem, GroupItem } from 'devextreme-react/form';
import { Column, DataGrid, Scrolling } from 'devextreme-react/data-grid';
import { PropTypes } from 'prop-types';
import { FORM_STYLING_MODE } from 'app-constants';
import dayjs from 'dayjs';
import styles from './../order.module.scss';


const getContractInfo = loader('./../gql/contractInfo.graphql');
const emptyDocData = { date: '', contract: '' };

const ContractInfoPopup = ({ doc, visible = true, onClose = () => {} }) => {
  const gqlClient = useApolloClient();
  const { date, contract: refContract } = doc || emptyDocData;
  const [ data, setData ] = useState({
    dogDate: '0001-01-01T00:00:00',
    dogNumber: '',
    id: '',
    isSubContract: false,
    name: '',
    nameForPrint: '',
    options: { prices: [], disconts: [] },
    validDate: '',
  });

  const load = async () => gqlClient.query({
    query: getContractInfo,
    variables: {
      ref: refContract?.contract || refContract,
      date: date || dayjs().format(),
    },
  }).then((response) => {
    const data = response?.data?.contractInfo;
    if (data) setData(data);
  });

  useEffect(() => {
    if (visible && refContract) load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ refContract, visible ]);

  return (
    <Popup
      closeOnOutsideClick
      onVisibleChange={onClose}
      visible={visible}
      title='Інформація по договору'
      showCloseButto
      height={500}
    >
      <div
        className="form-container"
        style={{ overflow: 'auto', height: '440px' } }
      >
        <Form
          formData={data}
          height='100%'
          labelLocation='left'
          scrollingEnabled={true}
        >
          <GroupItem colCount={2} >
            <SimpleItem label={{ text: 'Назва' }}
              colSpan={2}
              itemType='dxTextBox'
              dataField='name'
              editorOptions={{ readOnly: true, stylingMode: FORM_STYLING_MODE }}
            />
            <GroupItem colSpan={2} colCount={2}>
              <SimpleItem label={{ text: 'Дійсний до' }}
                itemType='dxTextBox'
                dataField='validDate'
                editorOptions={{
                  readOnly: true,
                  stylingMode: FORM_STYLING_MODE,
                }}
              />
              <SimpleItem label={{ text: 'Генпідряд' }}
                itemType='dxCheckBox'
                dataField='isSubContract'
                editorOptions={{
                  readOnly: true,
                  stylingMode: FORM_STYLING_MODE,
                }}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={1}>
            <label className='dx-field-item-label-location-top'>
            Особливі умови
            </label>
            <div className={styles.contractPopupInfoOptions} >
              <div>
                <label className='dx-field-item-label-location-top'>Ціни</label>
                <DataGrid dataSource={data?.options.prices} showBorders={true}>
                  <Scrolling mode='standard'/>
                  <Column dataField='nomname' caption='Номенклатура' />
                  <Column dataField='value' caption='Ціна' />
                  <Column dataField='validTo' caption='Термін дії' />
                </DataGrid>
              </div>
              <div className={styles.contractPopupInfoOptionsItemDivider}></div>
              <div>
                <label className='dx-field-item-label-location-top'>
                  Знижки</label>
                <DataGrid dataSource={data?.options.disconts}
                  showBorders={true}>
                  <Column dataField='nomname' caption='Номенклатура' />
                  <Column dataField='value' caption='Скидка(%)' />
                  <Column dataField='validTo' caption='Термін дії' />
                </DataGrid>
              </div>
            </div>
          </GroupItem>
        </Form>
      </div>
    </Popup>
  );
};

ContractInfoPopup.propTypes = {
  ...Popup.propTypes,
  onClose: PropTypes.func,
  doc: PropTypes.object,
};

export default ContractInfoPopup;
