import React, { useState } from 'react';
import { TextBox } from 'devextreme-react';
import PropTypes from 'prop-types';

import styles from './optionsBar.module.scss';
import { normalizeCarNumber } from 'utils/normalize-car-number';

export const OptionsBar = ({ onChange, onChangeEdrpou }) => {
  const [ gos_code, setGosCode ] = useState('');
  const [ edrpou, setEdrpou ] = useState('');
  return (
    <div className={styles.wrapper}>
      <div className={styles.caption}> Додаткові фільтри: </div>
      <div className={styles.filtfields}>
      <TextBox
        placeholder='Держ номер'
        label='Держ номер'
        stylingMode='outlined'
        showClearButton={true}
        value={gos_code}
        onValueChanged={({ value }) => {
          onChange(value);
          setGosCode(normalizeCarNumber(value));
        }}/>
        <TextBox
        placeholder='ЄДРПОУ'
        label='ЄДРПОУ'
        hint='код ЄДРПОУ починається з...'
        stylingMode='outlined'
        showClearButton={true}
        value={edrpou}
        onValueChanged={({ value }) => {
          onChangeEdrpou(value);
          setEdrpou(value);
        }}/>
        </div>
    </div>
  );
};

OptionsBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeEdrpou: PropTypes.func.isRequired,
};
