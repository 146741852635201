import {
  Button,
  DateBox,
  NumberBox,
  TextArea,
  TextBox
} from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './appoint-editor.module.scss';
import { PartnerSearch } from 'components/partner-search/partner-search';
import { FORM_STYLING_MODE } from 'app-constants';
import { OrdersSearch } from '../orders-search/orders-search';
import { sources } from './scheduler';
import dayjs, { min } from 'dayjs';
import { useDate } from 'hooks';

const durationCalc = (begin, end) => {
  if (end && begin) {
    return (end.getTime() - begin.getTime()) / 60000;
  }
  return 0;
};

const AppointEditor = (props) => {

  const [ data, setData ] = useState(props.data);
  const { endOfDay } = useDate();

  const handleOrderSelect = ({ ref, caption, date, partner }) => {
    setData((prev) => ({
      ...prev,
      buyers_order: { ref, caption, date, partner },
      partner: partner || prev.partner,
    }));
  };

  const handleChange = ({ element: { id }, value }) => {
    setData(prev => {
    const newState = { ...prev };
    newState[id] = value;

    if (id === 'duration' && newState.startDate) {
      newState.endDate = new Date(newState.startDate.getTime() + (value * 60000));
    }

    const maxEndDate = min([
      dayjs(newState.endDate ?? newState.startDate),
      dayjs(endOfDay(newState.startDate))
     ]);
    if (newState.startDate) {
      newState.endDate = maxEndDate.toDate();
      newState.duration = Math.trunc(durationCalc(newState.startDate, newState.endDate));
    }
    return newState;
    });
  };

  useEffect(() => {
    if (props.data.startDate) {
      props.data.duration = durationCalc(
        props.data.startDate, props.data.endDate,
      );
    }
    props.data.source = props.data?.source ?? 'lab';
    setData(props.data);
  }, [ props.data ]);

  return (
    <div className={styles.vblock} >
      <div className={styles.hblock}>
        <div className={styles.field}>
          <div className={styles.fieldLabel}>Початок</div>
          <DateBox
            id="startDate" stylingMode="outlined"
            value={data?.startDate}
            max={data?.endDate}
            type="datetime"
            interval={30}
            onValueChanged = {handleChange}
          />
        </div>
        <div className={styles.field}>
          <div className={styles.fieldLabel}>Закінчення</div>
          <DateBox
            id="endDate" stylingMode="outlined"
            value={data?.endDate}
            min={data?.startDate}
            onValueChanged = {handleChange}
            type="datetime" />
        </div>
      </div>
      <div className={styles.hblock}>
        <div className={styles.field}>
          <div className={styles.fieldLabel}>Джерело</div>
          <TextBox id="source" stylingMode="outlined"
            value={sources.find(({ id }) => id === data?.source)?.text ?? ''}
            // readOnly={true}
            // onValueChange = { setData(data) }
          />
        </div>
        <div className={styles.field}>
          <div className={styles.fieldLabel}>Тривалість</div>
          <NumberBox id="duration" stylingMode="outlined"
            value={data?.duration}
            min={0}
            showSpinButtons={true}
            width={85}
            step={5}
            onValueChanged = {handleChange} />
        </div>
      </div>
      <hr />
        <div className={styles.hblock}>
        <div className={styles.field} style={{ width: '100%' } }>
          <div className={styles.fieldLabel}>Коментар</div>
          <TextArea
            className={styles.fieldArea} stylingMode="outlined"
            id="note"
            value={data?.note}
            onValueChanged = {handleChange} />
        </div>
      </div>

      <div className={styles.field}>
        <div className={styles.fieldLabel}>Контрагент</div>
        <PartnerSearch
          partner={data?.partner}
          onSelect={({ ref, name }) => {
            const newState = {
              ...data,
              partner: {
                ref: ref || '',
                name: name || '',
              },
            };
            if (data?.buyers_order?.partner?.ref !== ref) newState.buyers_order = undefined;
            setData({ ...newState });
          }}
          stylingMode={FORM_STYLING_MODE}
        />
      </div>

      <div className={styles.field}>
        <div className={styles.fieldLabel}>Телефон</div>
        <TextBox
          value={data?.partner?.phones}
          readOnly
          width='100%'
          stylingMode={FORM_STYLING_MODE}
        />
      </div>

      <div className={styles.field}>
        <div className={styles.fieldLabel}>Замовлення</div>
        <OrdersSearch
          order={data?.buyers_order}
          onSelect={handleOrderSelect}
          readOnly={false}
          stylingMode={FORM_STYLING_MODE}
        />
      </div>
      <hr/>
      <div className={styles.hblock}>
        <div className={styles.field}>
          <div className={styles.fieldLabel}>Держ.номер</div>
          <TextBox id="plate_no" stylingMode="outlined"
            value={data?.plate_no ?? ''}
            readOnly={false}
            onValueChanged = {handleChange} />
        </div>
        <div className={styles.field}>
          <div className={styles.fieldLabel}>Кількість</div>
          <NumberBox id="count" stylingMode="outlined"
            value={data?.count ?? 0}
            min={0}
            // width={65}
            onValueChanged = {handleChange} />
        </div>
      </div>

      <div className={styles.hblock}>
        <div className={styles.field} style={{ width: '100%' } }>
          <div className={styles.fieldLabel}>Лід</div>
          <TextBox className={styles.fieldArea} stylingMode="outlined"
            id="lead"
            value={data?.lead ?? ''}
            onValueChanged = {handleChange} />
        </div>
      </div>

      <hr/>
      <Button 
        type="success" 
        text="Зберегти" 
        disabled={data?.source==='ringo'}
        onClick={() => props.updateData(data)}
        
      />
    </div>

  );
};

AppointEditor.propTypes = {
  data: PropTypes.object,
  updateData: PropTypes.func,
};
export default AppointEditor;
