import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { CopyButton } from 'components';
import { useScreenSize } from 'utils/media-query';
import { DATE_DISPLAY_FORMAT } from 'app-constants';
import styles from '../../pages/orders/order.module.scss';

const hintText = 'скопіювати номер документу';
const inStyles = {
  rowDiv: {
    display: 'flex',
  },
  label: {
    width: '80px',
    paddingLeft: '10px',
  },
  text: {
    width: '180px',
  },

};


export const DocHeader = ({ number_doc, date, statusText }) => {
  const screenSize = useScreenSize();
  return (
    <div className={screenSize.isXSmall ? '' : styles.orderInfoItem}>
      <div className={styles.orderInfo}>
        <div className={styles.orderNumber}>
          <div style={inStyles.rowDiv}>
            <span style={inStyles.label}>Номер:</span>
            <span style={inStyles.text}> {`${number_doc ?? ''}`}</span>
          </div>
          <CopyButton value={number_doc} hint={hintText}/>
        </div>
        <div style={inStyles.rowDiv}>
          <span style={inStyles.label}>Дата:</span>
          <span style={inStyles.text}>
            {`${dayjs(date).format(DATE_DISPLAY_FORMAT)}`}
          </span>
        </div>
        {statusText && <div style={inStyles.rowDiv}>
          <span style={inStyles.label}>Стан:</span>
          <span style={inStyles.text}>{statusText}</span>
        </div>
        }
      </div>
    </div>
  );
};

DocHeader.propTypes = {
  number_doc: PropTypes.string,
  date: PropTypes.any,
  statusText: PropTypes.string,
};
