export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'LOADING':
      if (payload !== state.loading) {
        return { ...state, loading: payload };
      }
      break;

    case 'UPDATE':
      return { ...state, ...payload };

    case 'UPDATE_DOC':
      if (payload.ext_json) {
        state.doc.ext_json = {
          ...state.doc.ext_json,
          ...payload.ext_json,
        };
        delete payload.ext_json;
      }
      Object.keys(payload).forEach((key) => {
        state.doc[key] = payload[key];
      });
      return { ...state };

    default:
      break;
  }
  return state;
};
