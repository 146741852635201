import React, { useEffect, useState } from 'react';
import { Button, Popup, TextBox } from 'devextreme-react';
import PropTypes from 'prop-types';
import { normalizePhoneNumber } from 'utils/phone-number';

export const PhonePopup = ({ currentPhone, visible, onValueChanged, onHide }) => {
  const [ phone, setPhone ] = useState(currentPhone);
  useEffect(() => {
    setPhone(currentPhone);
  }, [ currentPhone ]);

  return (
    <Popup
      visible={visible}
      onHiding={onHide}
      hideOnOutsideClick={true}
      closeOnOutsideClick={true}
      showCloseButton={true}
      width={400}
      height={120}
      showTitle={true}
      title="Заміна додаткового номера телефону"
      container=".dx-viewport"
    >
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <TextBox
          label="Додатковий номер"
          showClearButton
          onValueChange={(val) => setPhone(normalizePhoneNumber(val))}
          value={phone}
        />
        <Button onClick={() => onValueChanged(phone)}>Зберегти</Button>
      </div>
      наприклад: 380516001010
    </Popup>
  );
};

PhonePopup.propTypes = {
  currentPhone: PropTypes.string,
  onHide: PropTypes.func,
  onValueChanged: PropTypes.func,
  visible: PropTypes.bool,
};
