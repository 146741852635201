import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/auth';
import dayjs from 'dayjs';
import { paymentCreateOrder } from 'pages/insurance-policy/api/requests';
import QRCodePaymentForm from 'pages/insurance-policy/new-policy/payment_forms/qr_code';
import styles from './payment-form.module.scss';
import { Button, LoadPanel } from 'devextreme-react';
import { initapi } from 'pages/insurance-policy/api/services';

export const EasyPayWidget = (props) => {
  const { lab, options, projects } = useAuth();
  const doc = props.paymentData.doc;
  const [ easyPayData, setEasyPayData ] = useState(doc?.ext_json?.easyPayData);
  const paymentData = props.paymentData;
  const expire = dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
  const bankAcc = lab?.org.bank_accounts.find((acc) => acc.proj === doc.proj);
  const contractNumber = lab?.org.contract_easypay.number;
  const contractDate = lab?.org.contract_easypay.date;
  const projName = projects.nameByRef(doc.proj);

  useEffect(() => {
    if (easyPayData) return;

    initapi(options.assurance);

    const narrativeName = `Перерахування прийнятих платежів за послуги ${projName} згідно договору № ${contractNumber} від ${contractDate}р. та реєстру від [work_date]р, без ПДВ.`;
    const pData = {
      order: {
        orderId: doc.ref,
        description: paymentData.description,
        amount: paymentData.amount,
        expire,
        additionalItems: {
          'Merchant.Info': `${lab.lab_number},${projName},${doc.number_doc},${doc.ref}`,
        },
      },
      BankingDetails: {
        Payee: {
          Id: lab?.org.edrpou,
          Name: bankAcc.name,
          Bank: {
            Mfo: bankAcc.bank_mfo,
            name: bankAcc.bank_name,
            Account: bankAcc.account,
          },
        },
        Payer: { Name: `${doc?.partner?.name ?? "ФізОсоба"}` },
        Narrative: {
          Name: narrativeName,
        },
      },
    };
    paymentCreateOrder(pData, { serviceid: 18900 })
      .then((data) => {
        const dataObj = {
          amount: data.amount,
          forwardUrl: data.forwardUrl,
        };
        doc.ext_json.easyPayData = dataObj;
        doc.save();
        setEasyPayData(dataObj);
      })
      .catch(() => console.log('Помилка отримання'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ easyPayData ]);

  const Panel = () => (easyPayData ?
    <div>
      <div className={styles.easyPayButtons}>
      <div className={styles.easyPayLink}>
      <span className={styles.easyPayLinkHeader}> Оплата на сайті:</span>
        <a href={easyPayData.forwardUrl} target="_blank" rel="noreferrer">
          <div style={{ display: 'flex', alignItems: 'center', height: '200px', width: '200px' }}>
            <img width='200' height='64' src='https://cdn.easypay.ua/images/svg/logo.svg' alt='' />
          </div>
        </a>
        </div>
        <div className={styles.easyPayLink}>
        <span className={styles.easyPayLinkHeader}> Сканувати без додатку банку:</span>
        <QRCodePaymentForm data={easyPayData} />
        </div>
      </div>
      <div className={styles.buttonContainer} >
        <Button
          onClick={() => setEasyPayData(undefined)}
        >Перегенерувати ссилку</Button>
      </div>
    </div> :
    <LoadPanel visible={true} />
  );
  return (
    <Panel/>
  );
};

EasyPayWidget.propTypes = {
  paymentData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    description: PropTypes.oneOfType(
      [ PropTypes.string, PropTypes.element ],
    ).isRequired,
    proj: PropTypes.string.isRequired,
    data: PropTypes.object,
    organization: PropTypes.string,
    doc: PropTypes.any,
  }),
};
