import React, { useEffect, useRef } from 'react';
import { useAuth } from 'contexts/auth';
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
  Column,
  Button as CButton,
  StateStoring,
} from 'devextreme-react/data-grid';
import { Menu } from 'devextreme-react/menu';
import { useNavigate } from 'react-router-dom';

import {
  // COLUMN_DATE_WIDTH,
  // COLUMN_DOCNUMBER_WIDTH,
  // COLUMN_PARTNER_MINWIDTH,
  // COLUMN_EP_TYPE,
  // COLUMN_EP_VIN,
  // COLUMN_EP_CAR_NUMBER,
  COLUMN_EDIT_WIDTH,
  COLUMN_NOTE_MINWIDTH,
  uaFilterRowText,
} from 'app-constants';

import { useSubscription, gql } from '@apollo/client';
import { dsAssurance } from './dsAssurance';
import { DocInfoBar, StickyBar } from 'components';
import styles from '../styles/style.module.scss';


export const PolicyPage = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const gridRef = useRef();

  const sq = gql(`subscription {docChange(input: { username:"${user?.email ?? ''}" }, class_name:"doc.assurance" )}`);

  const { data: docChange, loading: loadingDocChange } = useSubscription(sq);

  useEffect(() => {
    if (!loadingDocChange) gridRef.current.instance.refresh(true);
  }, [ docChange, loadingDocChange ]);

  useEffect(() => {
    dsAssurance.on('loaded', (result) => {
      if (result?.errors) signOut();
    });
  });

  const handleEditIconClick = (e) => {
    if (e?.row || e?.data) {
      navigate(`/policy/${e?.row?.key || e?.data?.ref}`);
    }
  };

  return (
    <div>
      <StickyBar>
        <DocInfoBar name='Продаж Страхового Полісу'/>
        <Menu
          className={styles?.padding_0_10}
          onItemClick={(e) => {
            switch (e.itemData.id) {
            case 'new': {
              navigate({ pathname: '/policy-new' });
              break;
            }
            case 'close': {
              navigate({ pathname: '/home' });
              break;
            }
            case 'prices': {
              navigate({ pathname: '/policy/prices' });
              break;
            }
            case 'clear': {
              gridRef.current.instance.clearFilter();
              break;
            }
            default:
              break;
            }
          }}
          dataSource={[
            {
              icon: 'add',
              text: 'Додати',
              id: 'new',
            },
            {
              id: 'close',
              icon: 'close',
              text: 'Закрити',
            },
            {
              id: 'clear',
              icon: 'clearformat',
              text: 'Зняти всі фільтри',
            },
            {
              id: 'prices',
              icon: 'datafield',
              text: 'Прайс-Лист',
            },
          ]}
        />
      </StickyBar>
      <DataGrid
        ref={gridRef}
        id="gridContainer"
        className={styles?.ep_data_grid}
        highlightChanges={true}
        dataSource={dsAssurance}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        allowSorting={true}
        remoteOperations={true}
        onRowDblClick={handleEditIconClick}
      >
        <StateStoring enabled type="localStorage" storageKey="ep" />
        <Selection mode="single" />
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Paging pageSize={100} />
        <FilterRow visible={true} {...uaFilterRowText} />

        <Column type="buttons" width={COLUMN_EDIT_WIDTH}>
          <CButton name="_edit" icon="edit" onClick={handleEditIconClick} />
        </Column>

        <Column
          // policy_number
          dataField="body.policy_reserve.policy_number"
          caption="Поліс №"
          dataType="string"
          alignment="center"
          width={COLUMN_NOTE_MINWIDTH}
        />
        <Column
          // vehicle_plate_num
          dataField="body.vehicle_plate_num"
          caption="Держ. номер"
          dataType="string"
          alignment="left"
          width={COLUMN_NOTE_MINWIDTH}
        />
        <Column
          // vehicle_city
          dataField="body.vehicle_city"
          caption="Регион"
          dataType="string"
          alignment="left"
          minWidth={120}
        />
        <Column
          // type_of_insured_person
          dataField="body.type_of_insured_person"
          caption="Тип страхувальника"
          dataType="string"
          alignment="left"
          width={130}
        />
        <Column
          // end_date
          dataField="body.policy_confirm.end_date"
          // dataField="date"
          caption="Дійсний до"
          dataType="date"
          format="dd.MM.yyyy"
          alignment="center"
          width={COLUMN_NOTE_MINWIDTH}
        />
        <Column
          // payed_type
          dataField="body.payed_type"
          caption="Тип оплаты"
          dataType="string"
          alignment="left"
          width={COLUMN_NOTE_MINWIDTH}
        />
        <Column
          // selectedPrice
          dataField="body.companie.selectedPrice"
          caption="Сума"
          dataType="string"
          alignment="left"
          width={COLUMN_NOTE_MINWIDTH}
        />
      </DataGrid>
    </div>
  );
};
