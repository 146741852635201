import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { appInfo } from 'app-info.js';
import routes from 'app-routes.js';
import { SideNavOuterToolbar as SideNavBarLayout } from 'layouts';

export const Content = () => <SideNavBarLayout title={appInfo.title}>
  <Routes>
    {routes.map(({ path, element: Element }) => <Route
      exact
      key={path}
      path={path}
      element={<Element/>}
    />
    )}
   <Route path="*" element={<Navigate to='/dashboard' replace={true}/>} />
  </Routes >
</SideNavBarLayout>;

