export const types_message = [
  { code: 'soft', caption: 'програмне забезпечення' },
  { code: 'hard', caption: 'не працює термінал' },
  { code: 'finance', caption: 'фінанcи' },
  { code: 'other', caption: 'інше' },
];

export const statuses = [
  { code: 0, caption: 'відхилений', moderated: true },
  { code: 1, caption: 'новий', moderated: true },
  { code: 2, caption: 'прийнятий в роботу' },
  { code: 10, caption: 'виконаний', moderated: true },
  { code: 100, caption: 'на модерації', moderated: true },
];

