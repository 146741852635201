import { orderDocStatuses } from 'app-constants';

export const calculateStatus = (data) => {
  if (!data?.number_doc) {
    return orderDocStatuses.NEW;
  } else if (data.shipped >= data.doc_amount && data.paid >= data.doc_amount) {
    return orderDocStatuses.COMPLETED;
  } else if (data.paid >= data.doc_amount) {
    return orderDocStatuses.PAID;
  } else if (data.shipped >= data.doc_amount) {
    return orderDocStatuses.DONE;
  }

  return orderDocStatuses.ACCEPTED;
};
