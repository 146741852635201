import { Button, DataGrid, Lookup } from 'devextreme-react';
import { PageContent, PartnerSearch } from 'components';

import {
  Column,
  Grouping,
  GroupItem,
  GroupPanel,
  Paging,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import { dsDebitsByAct, dsPartners } from 'datasources';
import { uniqBy } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import FileSaver from 'file-saver';
import { exportExcel } from './utils.js';


let amnt0;

const calculateCustomSummary = (option) => {
  if (option.name === 'amnt') {
    switch (option.summaryProcess) {
    case 'start':
      amnt0 = [];
      break;
    case 'calculate':
      if (option.groupIndex === 1) {
        option.totalValue = option.value.trans.doc_amount;
      } else if (option.groupIndex === 0) {
        amnt0.push({
          ref: option.value.trans.ref,
          amnt: option.value.trans.doc_amount,
        });
      }
      break;
    case 'finalize':
      if (option.groupIndex === 0) {
        amnt0 = uniqBy(amnt0, 'ref');
        option.totalValue = amnt0.reduce((acc, r) => acc + r.amnt, 0);
      }
      break;
     default:
    }
  }
};

const headStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

const typeStyle = {
  display: 'flex',
  alignItems: 'center',
};
const dsLookup = [
  { id: 'a', text: 'Вся заборгованість' },
  { id: 'd', text: 'Заборгованість нам (дебіторка)' },
  { id: 'k', text: 'Заборгованість наша (кредиторка)' },
];
const debValue = (r) => (r.direction === 'd' ? r.amount : 0);
const krdValue = (r) => (r.direction === 'k' ? r.amount : 0);

export const DebitsByAct = () => {
  const dataGridRef = useRef(null);
  const { partner } = useParams();
  const [ groupIndex ] = useState([ 'partner', 'trans' ]);
  const [ partnerObj, setPartnerObj ] = useState({ ref: '', name: '' });

  useEffect(() => {
    if (partner) {
      dsPartners.byKey(partner).then((p) => {
        setPartnerObj(p);
      });
    }
  }, [ partner ]);

  dsDebitsByAct.userData = {
    ...dsDebitsByAct.userData,
    partner,
  };
  const navigate = useNavigate();

  const partnerGroupCell = ({ data }) => {
    const styles = {};
    if (data?.displayValue.includes('\u{26A0}')) {
      styles.backgroundColor = 'yellow';
    }

    return (
      <div style={styles}> {data.displayValue} </div>
    );
  };

  const orderGroupCell = ({ data }) => {
    const ref = data.data.collapsedItems?.length
      ? data.data.collapsedItems[0].trans.ref
      : (data.data.items?.length ? data.data.items[0].trans.ref : undefined);
    return ref ? <Link to={`/order/${ref}`}>
      {data.displayValue}
    </Link> : data.displayValue;
  };

  


  return (
    <PageContent size='large'>
      <div className='otk-page-header' style={headStyle} >
          Заборгованість за виконаними роботами
        <Button
          onClick={async () => {
            const items = dataGridRef.current.instance.getDataSource().items();
            const exportItems = items.map((r) => {
              const [ rest, d, k, ord ] = r.aggregates;
              return {
                partner: r.key,
                ord, d, k, rest,
              };
            });
            const fileName = 'debitorka.xlsx';
            const excelBuf = await exportExcel(fileName, exportItems);
            const dataExcell = new Blob([ excelBuf ]);
            FileSaver.saveAs(dataExcell, fileName);
          }}
        >Export Excell</Button>
      </div>
      <div style={headStyle}>
        <div style={typeStyle}>
          <a style={{ paddingRight: '5px' }} href="/#">Вид:</a>
          <Lookup
            dataSource={dsLookup}
            valueExpr='id'
            displayExpr='text'
            defaultValue={'a'}
            width = {280}
            onValueChange = {(v) => {
              dsDebitsByAct.userData = {
                ...dsDebitsByAct.userData,
                direction: v === 'a' ? undefined : v,
              };
              dataGridRef.current.instance?.refresh();
            }}

          />
          <a href="/#" style={{
            paddingLeft: '5px',
            paddingRight: '5px',
          }} >Контрагент:</a>
          <PartnerSearch
            partner={partnerObj}
            readOnly={true}
            label=''
            onSelect={(e) => navigate(`/debitsbyact_report/${e.ref}`)}
          />
          <Button
            icon='remove'
            onClick={() => navigate('/debitsbyact_report')}
          />
        </div>
      </div>
      <DataGrid
        ref={dataGridRef}
        key='ref'
        showBorders
        dataSource={dsDebitsByAct}
        columnAutoWidth
        allowSorting
      >
        <Paging enabled={false} />
        <Grouping
          autoExpandAll={false}
          expandMode='rowClick'
          contextMenuEnabled={true}
          texts={[]}
        />
        <GroupPanel visible={true} />

        <Column
          dataField='partner' caption='Контрагент'
          calculateDisplayValue={(r) => (`${r.partner.name} ${
            r.partner.isWarn ? ' \u{26A0}' : ''}`) } // warning char
          width={350}
          groupIndex={groupIndex.findIndex((r) => (r === 'partner'))}
          groupCellComponent={partnerGroupCell}
        />
        <Column
          dataField='trans' caption='Замовлення'
          calculateDisplayValue={ (r) => `${r.trans?.caption} (${r.trans?.proj ?? ''})` }
          width={450}
          groupIndex={groupIndex.findIndex((r) => (r === 'trans'))}
          groupCellComponent={orderGroupCell}
        />
        <Column dataField='key_field_desc'  caption='' width={450} />
        <Column name='amnt' caption='Замовл' alignment='right' />
        <Column dataField='act' caption='Акти' alignment='right' calculateCellValue={debValue}/>
        <Column dataField='pay' caption='Опл.' alignment='right' calculateCellValue={krdValue}/>
        <Column dataField='amount' caption='Борг'/>

        <Summary calculateCustomSummary={calculateCustomSummary}>
          <GroupItem
            column='amount'
            summaryType='sum'
            displayFormat='{0}'
            valueFormat='0#.##'
            alignByColumn={true} />

          <GroupItem
            column='act'
            summaryType='sum'
            displayFormat='{0}'
            valueFormat='0#.##'
            alignByColumn={true} />

          <GroupItem
            column='pay'
            summaryType='sum'
            valueFormat='decimal'
            displayFormat='{0}'
            alignByColumn={true} />

          <GroupItem
            name='amnt'
            summaryType='custom'
            showInColumn='amnt'
            displayFormat="{0}"
            valueFormat='0#.##'
            alignByColumn={true}
          />
          <TotalItem column='act' displayFormat='{0}' valueFormat='0#.##' summaryType='sum' />
          <TotalItem column='pay' displayFormat='{0}' valueFormat='0#.##' summaryType='sum' />
          <TotalItem column='amount' displayFormat='{0}' valueFormat='0#.##' summaryType='sum' />
        </Summary>
      </DataGrid>
    </PageContent>
  );
};
